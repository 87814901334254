import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import EditCardWrapper from '../../../../../../components/EditCardWrapper/EditCardWrapper.jsx';
import { Grid } from '@mui/material';
import _ from 'lodash';
import { EditProductVariantOptions } from './EditProductVariantOptions/EditProductVariantOptions.jsx';
import Pricing from '../../../GeneralProductInformation/Pricing.jsx';
import Media from '../../../BrandMedia/Media.jsx';
import Editor from '../../../../../../components/Editor/Editor.jsx';
import ProductIDs from '../../../GeneralProductInformation/ProductIDs.jsx';
import { useLocation } from 'react-router-dom';

function ProductVariantEditPage({ selectedVariant, onChange, onValidityChange, variantFieldsValidity }) {
    const handleChange = (event) => {
        const { name, value } = event.target;
        const selectedClone = _.cloneDeep(selectedVariant);

        if (!_.isEmpty(selectedVariant)) {
            selectedClone[name] = value;
        }
        onChange(selectedClone);
    };

    function handleOptionChange(update) {
        const { name, value } = update;
        const selectedClone = _.cloneDeep(selectedVariant);

        if (!_.isEmpty(selectedVariant)) {
            selectedClone[name] = value;
        }
        onChange(selectedClone);
    }

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    function handleValidity(e) {
        const v = e?.target?.value;
        const selectedValidity = _.find(variantFieldsValidity, { id: selectedVariant?.id });
        if (selectedValidity) {
            const newValidity = { ...selectedValidity };
            onValidityChange(_.set(newValidity, e?.target?.name, _.isEmpty(v)));
        } else {
            const newValidity = { id: selectedVariant?.id };
            onValidityChange(_.set(newValidity, e?.target?.name, _.isEmpty(v)));
        }
    }

    const selectedValidity = _.find(variantFieldsValidity, { id: selectedVariant?.id });
    const links = selectedVariant?.imageLinks || [];
    const description = selectedVariant?.description || '';

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
                <EditCardWrapper title={'Options'}>
                    <Grid container spacing={2}>
                        <EditProductVariantOptions selected={selectedVariant} onChange={handleOptionChange} />
                    </Grid>
                </EditCardWrapper>
                <EditCardWrapper title={'Variant Pricing'}>
                    <Pricing
                        product={selectedVariant}
                        onChange={handleChange}
                        handleValidity={handleValidity}
                        fieldsValidity={selectedValidity}
                    />
                </EditCardWrapper>
                <EditCardWrapper title={'Variant Media'}>
                    <Media
                        images={links}
                        handleImagesChange={(v) => {
                            const e = {
                                target: {
                                    value: v,
                                    name: 'imageLinks',
                                },
                            };
                            handleChange(e);
                        }}
                    />
                </EditCardWrapper>
                <EditCardWrapper title={'Variant Info'}>
                    <Editor
                        value={description}
                        placeholder="Description"
                        onChange={(v) => {
                            const e = {
                                target: {
                                    value: v,
                                    name: 'description',
                                },
                            };
                            handleChange(e);
                        }}
                    />
                </EditCardWrapper>
                <EditCardWrapper title={'Variant IDs'}>
                    <ProductIDs
                        product={selectedVariant}
                        onChange={handleChange}
                        handleValidity={handleValidity}
                        fieldsValidity={selectedValidity}
                    />
                </EditCardWrapper>
            </Grid>
        </Grid>
    );
}

export default ProductVariantEditPage;

ProductVariantEditPage.propTypes = {
    onChange: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func.isRequired,
    selectedVariant: PropTypes.object.isRequired,
    variantFieldsValidity: PropTypes.array,
};
