import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { selectOrderTotalValue, selectSubtotalValue, selectTotalQuantity } from '../../cart/useCart.js';
import BusinessLogo from '../../components/BusinessLogo.jsx';
import { BusinessTypes } from '../../constants/BusinessTypes.jsx';
import { OrderDiscountTypes } from '../../constants/OrderDiscountTypes.jsx';
import { OrderStatuses } from '../../constants/OrderStatuses.jsx';
import { OrderTermsTypes } from '../../constants/OrderTermsTypes.jsx';
import CancelOrderDialog from '../retail/orders/CancelOrderDialog';
import { useFlags } from 'launchdarkly-react-client-sdk';

const states = {
    BRAND_REVIEW: 'BRAND_REVIEW',
    BRAND_SALES_REP: 'BRAND_SALES_REP',
    CART: 'CART',
    CHECKOUT: 'CHECKOUT',
    ORDER: 'ORDER',
    SUCCESS: 'SUCCESS',
};

export default function OrderSummaryCard(props) {
    const { business: { type: userBusinessType } = {} } = useRouteLoaderData('root') || {};
    const { business, order, products = [], variant = states.CART, state = {} } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const flags = useFlags();
    const [showCancelOrderDialogue, setShowCancelOrderDialogue] = React.useState(false);

    let discountAmount = 0;
    let discountType = OrderDiscountTypes.DOLLARS;
    let shippingCost = 0;
    let status;
    let subtotal = products?.reduce((acc, curr) => acc + curr.wholesale * curr.quantity, 0);
    let orderTotal = subtotal + shippingCost - discountAmount;
    let terms = OrderTermsTypes.PAY_UP_FRONT;
    let totalQuantity = products?.reduce((acc, curr) => acc + curr.quantity, 0);

    if (variant === states.BRAND_SALES_REP) {
        discountAmount = state.discountAmount;
        discountType = state.discountType;
        orderTotal = selectOrderTotalValue(state);
        shippingCost = state.shippingCost;
        subtotal = selectSubtotalValue(state);
        terms = state.terms || OrderTermsTypes.PAY_UP_FRONT;
        totalQuantity = selectTotalQuantity(state);
    }

    if ((variant === states.ORDER || states.BRAND_REVIEW) && order) {
        discountAmount = order.discountAmount;
        discountType = order.discountType;
        orderTotal = order.total;
        shippingCost = order.shippingCost;
        status = order.status;
        subtotal = order.subTotal || selectSubtotalValue(order);
        terms = order.terms || OrderTermsTypes.PAY_UP_FRONT;
        totalQuantity = order?.orderItems
            ? order.orderItems?.reduce((acc, curr) => acc + parseInt(curr.quantity), 0)
            : selectTotalQuantity(order);
    }

    const handleCancelOrder = () => {
        setShowCancelOrderDialogue(true);
    };

    const handleViewAllOrdersClick = () => {
        navigate('/retail/orders');
    };

    const handleViewInvoice = () => {
        // TODO
    };

    return (
        <Card
            sx={{
                textAlign: 'center',
                // Updating this for the retailer experience, eventually will be for both
                width: { sm: userBusinessType === BusinessTypes.RETAIL ? 326 : 240 },
                ...props?.sx,
            }}>
            {props.hideTitle ? null : (
                <Box sx={{ p: 2 }}>
                    <Typography>{t('OrderSummaryCard.title')}</Typography>
                </Box>
            )}
            {props.hideLogo ? null : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <BusinessLogo business={business} sx={{ height: '104px', width: '104px' }} />
                </Box>
            )}
            <CardContent>
                {props.hideBusinessName ? null : (
                    <>
                        <Typography>{business?.name}</Typography>
                        <Divider sx={{ my: 2 }} />
                    </>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>{t('OrderSummaryCard.totalQuantityLabel')}</Box>
                    <Box>{totalQuantity}</Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>{t('OrderSummaryCard.subtotalLabel')}</Box>
                    <Box>
                        <NumericFormat
                            prefix="$"
                            displayType="text"
                            value={subtotal}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>{t('OrderSummaryCard.shippingLabel')}</Box>
                    <Box>
                        {props?.ShippingComponent ||
                            (!shippingCost ? null : (
                                <NumericFormat
                                    prefix="$"
                                    displayType="text"
                                    value={shippingCost}
                                    thousandSeparator
                                    decimalScale={2}
                                    fixedDecimalScale
                                />
                            )) ||
                            '—'}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>{t('OrderSummaryCard.discountLabel')}</Box>
                    <Box>
                        {props?.DiscountComponent ||
                            (!discountAmount ? null : (
                                <NumericFormat
                                    prefix={discountType === OrderDiscountTypes.DOLLARS ? '$' : undefined}
                                    suffix={discountType === OrderDiscountTypes.PERCENT ? '%' : undefined}
                                    displayType="text"
                                    value={discountAmount}
                                    thousandSeparator
                                    decimalScale={2}
                                    fixedDecimalScale
                                />
                            )) ||
                            '—'}
                    </Box>
                </Box>
                {flags.displayOrderTerms ? (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>{t('OrderSummaryCard.termsLabel')}</Box>
                        <Box>{terms}</Box>
                    </Box>
                ) : null}

                <Divider sx={{ my: 2 }} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 3,
                        color: 'common.blueTypography',
                    }}>
                    <Typography>
                        <b>{t('OrderSummaryCard.orderTotalLabel')}</b>
                    </Typography>
                    <Typography>
                        <b>
                            <NumericFormat
                                prefix="$"
                                displayType="text"
                                value={orderTotal}
                                thousandSeparator
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </b>
                    </Typography>
                </Box>
                {(variant === states.ORDER || variant === states.BRAND_REVIEW) && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 3,
                        }}>
                        <Typography variant="h6" component="p">
                            <b>
                                {t('OrderSummaryCard.statusLabel')} {status}
                            </b>
                        </Typography>
                    </Box>
                )}
                {!props?.OrderSummaryCardActions ? null : props.OrderSummaryCardActions}
                <Box sx={{ '>div': { my: 1 } }}>
                    {variant === states.ORDER && status === OrderStatuses.IN_REVIEW && (
                        <>
                            <Box>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    onClick={handleCancelOrder}>
                                    {t('OrderSummaryCard.buttons.cancelOrderLabel')}
                                </Button>
                            </Box>
                            <CancelOrderDialog
                                orderId={order?.id}
                                open={showCancelOrderDialogue}
                                onClose={() => setShowCancelOrderDialogue(false)}
                                onSuccess={() => navigate('.', { replace: true })}
                                businessId={business?.businessId}
                            />
                            <Box>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={handleViewAllOrdersClick}>
                                    {t('OrderSummaryCard.buttons.viewAllOrdersLabel')}
                                </Button>
                            </Box>
                        </>
                    )}
                    {variant === states.SUCCESS ? (
                        <Box>
                            <Button
                                fullWidth
                                variant="contained"
                                size="small"
                                color="secondary"
                                onClick={handleViewInvoice}>
                                {t('OrderSummaryCard.buttons.viewInvoiceLabel')}
                            </Button>
                        </Box>
                    ) : null}
                    {variant === states.ORDER && status === OrderStatuses.CANCELLED && (
                        <Box>
                            <Button
                                fullWidth
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={handleViewAllOrdersClick}>
                                {t('OrderSummaryCard.buttons.viewAllOrdersLabel')}
                            </Button>
                        </Box>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
}

OrderSummaryCard.propTypes = {
    OrderSummaryCardActions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    business: PropTypes.any,
    order: PropTypes.any,
    products: PropTypes.any,
    DiscountComponent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    ShippingComponent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    variant: PropTypes.oneOf(Object.values(states)),
    state: PropTypes.any,
    sx: PropTypes.any,
    hideTitle: PropTypes.bool,
    hideLogo: PropTypes.bool,
    hideBusinessName: PropTypes.bool,
};
