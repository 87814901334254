import React from 'react';
import StickyHeader from '../../components/StickyHeader/StickyHeader.jsx';
import ThatchHeader from '../../components/StickyHeader/ThatchHeader.jsx';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import backgroundImage from '../../assets/LandingPageBG.png';
import aboutImage from '../../assets/wholeshop-logo--icon-light.png';
import danImage from '../../assets/dan.webp';
import aboutComputer from '../../assets/aboutComputer.webp';
import HandShakeCircle from '../../assets/handShakeCircle.svg?react';
import ChartCircle from '../../assets/chartCircle.svg?react';
import PeopleCircle from '../../assets/peopleCircle.svg?react';
import ViewFinderCircle from '../../assets/viewFinderCircle.svg?react';
import { Midnight, SoftBG, Straw, Tide, White } from '../../theme.js';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import LandingIconCard from '../../components/LandingIconCard/AboutIconCard.jsx';
import LandingFooter from '../../components/LandingFooter/LandingFooter.jsx';
import Grid from '@mui/material/Grid';
import { Trans } from 'react-i18next';
import GoogleAnalytics from '../../components/GoogleAnalytics/GoogleAnalytics.jsx';
import HubSpotAnalytic from '../../components/HubSpotAnalytic/HubSpotAnalytic.jsx';

export default function AboutPage() {
    const responsiveDirection = { xs: 'column', sm: 'row' };
    const imageSize = {
        marginBottom: '45px',
        width: { xs: 125, sm: 150, md: 175 },
        height: { xs: 125, sm: 150, md: 175 },
    };
    const demoLink = 'https://hs.whole.shop/demo-sign-up';

    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', overflow: 'auto' }}>
            <GoogleAnalytics trackingId={'G-TJVBNR01V6'} />
            <HubSpotAnalytic />
            <ThatchHeader />
            <StickyHeader />
            <Stack
                sx={{
                    width: '100%',
                    height: '100%',
                    flexGrow: 1,
                    overflow: 'auto',
                }}>
                {/* Section 1 */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: { xs: 'flex-end', sm: 'center' },
                        width: '100%',
                        height: { xs: 700, sm: 350 },
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        paddingTop: 5,
                    }}>
                    <Stack
                        direction={responsiveDirection}
                        sx={{
                            gap: { xs: 4, sm: 0 },
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mx: 10,
                            width: '100%',
                            paddingTop: '125px',
                        }}>
                        <Stack gap={1} sx={{ mr: { xs: 0, sm: 5 }, ml: { xs: 2, sm: 0 } }}>
                            <Typography variant="displayMd" sx={{ color: Straw }}>
                                <Trans i18nKey="AboutPage.about" />
                            </Typography>
                            <Typography variant="textLg" sx={{ color: White, lineHeight: '32px' }}>
                                <Trans i18nKey="AboutPage.aboutDetail" />
                            </Typography>
                        </Stack>
                        <Box component="img" src={aboutImage} alt="Image1" sx={{ ...imageSize }} />;
                    </Stack>
                </Box>
                {/* Section 2 */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        minHeight: { xs: 1000, sm: 900, md: 575 },
                        paddingX: { xs: 3, sm: 7, md: 12 },
                        paddingY: { xs: 5, sm: 7, md: 10 },
                        boxSizing: 'border-box',
                    }}>
                    <Card
                        sx={{
                            backgroundColor: SoftBG,
                            height: '100%',
                            width: '100%',
                        }}>
                        <Stack
                            direction={responsiveDirection}
                            sx={{
                                height: '100%',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}>
                            <Stack gap={1} sx={{ m: 5, minWidth: '220px' }}>
                                <Box
                                    component="img"
                                    src={danImage}
                                    alt="Dan"
                                    sx={{
                                        height: '64px',
                                        width: '64px',
                                    }}
                                />
                                <Typography variant="displaySm">
                                    <Trans i18nKey="AboutPage.articleTitle" />
                                </Typography>
                                <Typography variant="p" sx={{ textTransform: 'uppercase' }}>
                                    <Trans i18nKey="AboutPage.articleSubTitle" />
                                </Typography>
                            </Stack>
                            <Box
                                alt="Story"
                                sx={{
                                    mr: { xs: 5, sm: 10 },
                                    minWidth: '320px',
                                    alignSelf: { xs: 'left', sm: 'flex-end' },
                                    marginTop: '38px',
                                    marginBottom: '38px',
                                    marginLeft: '25px',
                                    marginRight: '25px',
                                }}>
                                <Typography variant="body1">
                                    <Trans i18nKey="AboutPage.article" />
                                </Typography>
                            </Box>
                        </Stack>
                    </Card>
                </Box>
                {/* About Dan */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        minHeight: { xs: 950, md: 553 },
                        boxSizing: 'border-box',
                    }}>
                    <Stack gap={{ xs: 2, sm: 6 }} direction={responsiveDirection} sx={{ alignItems: 'center' }}>
                        <Stack gap={1} sx={{ maxWidth: 800, mx: 3 }}>
                            <Typography variant="displayMd" sx={{ color: Midnight }}>
                                <Trans i18nKey="AboutPage.subArticleTitle" />
                            </Typography>
                            <Stack gap={4}>
                                <Typography variant="body" sx={{ color: Midnight }}>
                                    <Trans i18nKey="AboutPage.subArticle" />
                                </Typography>
                            </Stack>
                        </Stack>
                        <Box component="img" src={aboutComputer} alt="Computer Keyboards" sx={imageSize} />
                    </Stack>
                </Box>
                {/* Research and Discovery Section */}
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden',
                        backgroundColor: Tide,
                        paddingY: 8,
                        paddingX: { xs: 10, md: 15 },
                        mx: 'auto',
                    }}>
                    <Stack gap={7} sx={{ alignItems: 'center', width: '100%' }}>
                        <Typography variant="displayMd" sx={{ color: Straw, textAlign: 'center', mx: 2 }}>
                            <Trans i18nKey="AboutPage.cardsTitle" />
                        </Typography>
                        <Grid
                            container
                            spacing={{ xs: 8, sm: 6, md: 4 }} // Set minimum spacing
                            justifyContent="center"
                            alignItems="stretch"
                            sx={{ width: '100%' }}>
                            <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center', minWidth: 280, flexShrink: 1 }}>
                                <LandingIconCard
                                    icon={HandShakeCircle}
                                    title={<Trans i18nKey="AboutPage.cards.0.cardTitle" />}
                                    description={<Trans i18nKey="AboutPage.cards.0.cardDescription" />}
                                    sx={{ width: '100%', maxWidth: 300, height: 320 }}></LandingIconCard>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center', minWidth: 280, flexShrink: 0 }}>
                                <LandingIconCard
                                    icon={ChartCircle}
                                    title={<Trans i18nKey="AboutPage.cards.1.cardTitle" />}
                                    description={<Trans i18nKey="AboutPage.cards.1.cardDescription" />}
                                    sx={{ width: '100%', maxWidth: 300, height: 320 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center', minWidth: 280, flexShrink: 0 }}>
                                <LandingIconCard
                                    icon={PeopleCircle}
                                    title={<Trans i18nKey="AboutPage.cards.2.cardTitle" />}
                                    description={<Trans i18nKey="AboutPage.cards.2.cardDescription" />}
                                    sx={{ width: '100%', maxWidth: 300, height: 320 }}
                                />
                            </Grid>
                            <Grid item xs={13} sm={6} md={3} sx={{ textAlign: 'center', minWidth: 280, flexShrink: 0 }}>
                                <LandingIconCard
                                    icon={ViewFinderCircle}
                                    title={<Trans i18nKey="AboutPage.cards.3.cardTitle" />}
                                    description={<Trans i18nKey="AboutPage.cards.3.cardDescription" />}
                                    sx={{ width: '100%', maxWidth: 300, height: 320 }}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            href={demoLink}
                            variant="contained"
                            sx={{ backgroundColor: White, color: Midnight, width: 284 }}>
                            <Trans i18nKey="LandingPage.getStartedButton" />
                        </Button>
                    </Stack>
                </Box>

                {/* Footer */}
                <LandingFooter />
            </Stack>
        </Box>
    );
}
