import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import ArrowCircleLeftIcon from '../../icons/ArrowCircleLeftIcon.jsx';
import * as api from '../../api.js';
import { useFetcher, useLoaderData, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Midnight } from '../../theme.js';
import DealerDetailsCard from '../DealerDetailsCard/DealerDetailsCard.jsx';
import IconButton from '@mui/material/IconButton';
import DealerAddresses from '../DealerAddresses/DealerAddresses.jsx';
import DealerActivity from '../DealerActivity/DealerActivity.jsx';
import DealerLastOrder from '../DealerLastOrder/DealerLastOrder.jsx';
import Typography from '@mui/material/Typography';
import DealerSettingsSidebar from '../DealerSettingsSidebar/DealerSettingsSidebar.jsx';
import { CircularProgress } from '@mui/material';
import { useError } from '../Error/ErrorProvider.jsx';
import { useTranslation } from 'react-i18next';

export async function loader({ request, params }) {
    const dealerId = params.id;
    const dealer = await api.getDealer(dealerId, request.signal);
    const connectionSettings = await api.getConnectionSettings({
        connectedBusinessId: dealerId,
        options: request.signal,
    });
    const availableStatuses = await api.getRetailerStatuses(request.signal);
    return { dealer, connectionSettings, availableStatuses };
}

export async function action({ request }) {
    try {
        const body = await request.json();
        const { intent, id } = body;

        if (intent === 'updateSettings') {
            const settings = body.settings;
            return await api.updateConnectionSettings({ id, body: settings, options: request.signal });
        }

        return null;
    } catch (error) {
        return { error };
    }
}

export default function DealerPage() {
    const data = useLoaderData();
    const navigate = useNavigate();
    const fetcher = useFetcher();
    const { dealer = {}, connectionSettings = {}, availableStatuses = [] } = data || {};
    const [isLoading, setIsLoading] = useState(false);
    const { handleError } = useError();
    const { t } = useTranslation();
    const navigateBackToDealers = () => {
        navigate('/brand/connections');
    };

    const handleSaveNote = (note) => {
        setIsLoading(true);
        fetcher.submit(
            {
                intent: 'updateSettings',
                id: dealer.id,
                settings: { ...connectionSettings, note },
            },
            { method: 'put', encType: 'application/json' }
        );
    };

    const handleStatusChange = (status) => {
        setIsLoading(true);
        fetcher.submit(
            {
                intent: 'updateSettings',
                id: dealer.id,
                settings: { ...connectionSettings, status },
            },
            { method: 'put', encType: 'application/json' }
        );
    };

    useEffect(() => {
        if (fetcher.state === 'idle' && isLoading) {
            setIsLoading(false);
        }
        if (fetcher.data?.error) {
            handleError(fetcher.data.error);
        }
    }, [fetcher, isLoading]);

    return (
        <Stack gap={1} sx={{ alignItems: 'flex-start', width: '100%' }}>
            <Box sx={{ height: 55, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <Stack direction="row" gap={2} sx={{ alignItems: 'center ' }}>
                    <IconButton onClick={navigateBackToDealers} sx={{ color: Midnight, padding: 0 }}>
                        <Stack gap={1.5} direction="row" sx={{ alignItems: 'center' }}>
                            <ArrowCircleLeftIcon sx={{ width: 24, height: 24 }} />
                            <Typography
                                noWrap
                                variant="textMd"
                                sx={{
                                    fontWeight: 700,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}>
                                {t('Dealers.backButton')}
                            </Typography>
                        </Stack>
                    </IconButton>
                    {isLoading ? <CircularProgress size={15} /> : null}
                </Stack>
            </Box>
            <Stack gap={2} direction={{ xs: 'column', sm: 'row' }} sx={{ width: '100%' }}>
                <Stack gap={2} sx={{ width: '100%' }}>
                    <DealerDetailsCard dealer={dealer} />
                    <DealerAddresses dealer={dealer} />
                    <DealerActivity dealer={dealer} />
                    {dealer.lastOrder ? <DealerLastOrder order={dealer.lastOrder} /> : null}
                </Stack>
                <DealerSettingsSidebar
                    dealer={dealer}
                    connectionSettings={connectionSettings}
                    availableStatuses={availableStatuses.map((status) => status.name)}
                    onSaveNote={handleSaveNote}
                    onStatusChange={handleStatusChange}
                />
            </Stack>
        </Stack>
    );
}
