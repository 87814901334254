import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import ProductIcon from '../../../../components/ProductIcon.jsx';
import ProductQuantityTextField from '../../../../components/ProductQuantityTextField/ProductQuantityTextField.jsx';
import RemoveOrderItemDialog from '../RemoveOrderItemDialog.jsx';

export default function BrandOrderProductCard({ product, isEditable = false, onChange, onDelete }) {
    const { t } = useTranslation();
    const [openDeleteProductDialog, setOpenDeleteProductDialog] = useState(false);
    const handlePriceChange = (value) => {
        const update = {
            ...product,
            price: `${value}`,
        };
        onChange(update);
    };

    const handleQtyChange = (quantity) => {
        const update = {
            ...product,
            quantity: `${quantity}`,
        };
        onChange(update);
    };

    const removeProduct = () => {
        handleCloseModal();
        onDelete(product);
    };
    const handleCloseModal = () => {
        setOpenDeleteProductDialog(false);
    };

    const imageSrc = product?.productVariant?.imageLinks?.[0] || product?.productVariant?.product?.imageLinks?.[0];
    return (
        <Box>
            <Card sx={{ display: 'flex' }}>
                <ProductIcon src={imageSrc} sx={{ width: '151px', height: '151px', pl: '12px' }} />
                <CardContent sx={{ width: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: {
                                xs: 'wrap',
                                sm: 'unset',
                            },
                            width: '100%',
                            justifyContent: 'space-between',
                            gap: 1,
                        }}>
                        <Grid container direction="row" justifyContent="space-between">
                            <Grid item xs={12} sm={6}>
                                <Stack alignItems="flex-start">
                                    <Typography component="div" variant="h5">
                                        <b>{product.productVariant.title}</b>
                                    </Typography>
                                    {product?.productVariant.variantTags?.map((t) => (
                                        <Typography key={t.name}>{t.value}</Typography>
                                    ))}
                                    <Box style={{ margin: '16px 0' }} />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            flexWrap: 'wrap',
                                        }}>
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            {t('Cart.skuLabel')} {product.productVariant.sku} <br />
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            {t('Cart.mpnLabel')} {product?.productVariant?.mpn}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Stack spacing={1} alignItems="flex-end">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'right',
                                            gap: 1,
                                        }}>
                                        {isEditable ? (
                                            <Stack direction="row">
                                                <IconButton
                                                    sx={{ color: 'common.removeColor' }}
                                                    onClick={() => setOpenDeleteProductDialog(true)}>
                                                    <DeleteOutline />
                                                </IconButton>
                                                <ProductQuantityTextField
                                                    quantity={+product.quantity}
                                                    onChange={(newValue) => handleQtyChange(newValue)}
                                                    sx={{ width: '100px' }}
                                                />
                                            </Stack>
                                        ) : (
                                            <Typography fontWeight="bold" variant="h6">
                                                Quantity: {product.quantity}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box sx={{ textAlign: 'right' }}>
                                        <Typography variant="h6">
                                            <b>
                                                <NumericFormat
                                                    prefix="$"
                                                    displayType={isEditable ? 'input' : 'text'}
                                                    defaultValue={product?.price}
                                                    thousandSeparator
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    valueIsNumericString
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'center',
                                                        borderRadius: '5px',
                                                        border: isEditable ? '1px solid #ccc' : 'none',
                                                    }}
                                                    onValueChange={(values) => {
                                                        const { value } = values;
                                                        handlePriceChange(value);
                                                    }}
                                                />
                                            </b>
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
            <RemoveOrderItemDialog
                open={openDeleteProductDialog}
                onClose={handleCloseModal}
                onConfirm={removeProduct}
            />
        </Box>
    );
}

BrandOrderProductCard.propTypes = {
    product: PropTypes.object,
    isEditable: PropTypes.bool,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
};
