import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

export default function CaretCircleDownIcon(props) {
    return (
        <Box component="svg" sx={props?.sx} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM16.2806 9.96937C16.3504 10.039 16.4057 10.1217 16.4434 10.2128C16.4812 10.3038 16.5006 10.4014 16.5006 10.5C16.5006 10.5986 16.4812 10.6962 16.4434 10.7872C16.4057 10.8783 16.3504 10.961 16.2806 11.0306L12.5306 14.7806C12.461 14.8504 12.3783 14.9057 12.2872 14.9434C12.1962 14.9812 12.0986 15.0006 12 15.0006C11.9014 15.0006 11.8038 14.9812 11.7128 14.9434C11.6218 14.9057 11.539 14.8504 11.4694 14.7806L7.71938 11.0306C7.57865 10.8899 7.49959 10.699 7.49959 10.5C7.49959 10.301 7.57865 10.1101 7.71938 9.96937C7.86011 9.82864 8.05098 9.74958 8.25 9.74958C8.44903 9.74958 8.6399 9.82864 8.78063 9.96937L12 13.1897L15.2194 9.96937C15.289 9.89964 15.3718 9.84432 15.4628 9.80658C15.5538 9.76884 15.6514 9.74941 15.75 9.74941C15.8486 9.74941 15.9462 9.76884 16.0372 9.80658C16.1283 9.84432 16.211 9.89964 16.2806 9.96937Z"
                fill="currentColor"
            />
        </Box>
    );
}

CaretCircleDownIcon.propTypes = {
    sx: PropTypes.any,
};
