import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { redirect, useFetcher, useLoaderData, useSearchParams } from 'react-router-dom';
import * as api from '../../api.js';
import Crumbs from '../../components/Crumbs/Crumbs.jsx';
import DisconnectShopifyModal from '../../components/DisconnectShopifyModal/DisconnectShopifyModal.jsx';
import FormInputLabel from '../../components/FormInputLabel/FormInputLabel.jsx';
import MapPinIcon from '../../icons/MapPinIcon.jsx';
import ShopifyIcon from '../../icons/ShopifyIcon.jsx';
import * as businessProvider from '../../providers/businessProvider.js';
import { Teal } from '../../theme.js';
import { useError } from '../../components/Error/ErrorProvider.jsx';

/**
 * This handles the submit for Shopify Integration
 * @param {*}
 * @returns
 */
export async function loader({ request }) {
    const business = await businessProvider.getBusiness({ signal: request.signal });
    return {
        business,
    };
}

/**
 * This handles the submit for Shopify Integration
 * @param {*}
 * @returns
 */
export async function action({ request }) {
    const formData = await request.formData();
    const storeName = formData.get('storeName');
    const intent = formData.get('intent');
    if (intent === 'connect') {
        try {
            const payload = {
                businessId: formData.get('businessId'),
                businessName: formData.get('businessName'),
            };
            const session = await api.updateShopifySession(storeName, payload, { signal: request.signal });
            return { session };
        } catch (error) {
            return { error };
        }
    }

    try {
        const authUrl = await api.integrateShopify({ storeName }, request);
        return redirect(authUrl);
    } catch (error) {
        // TODO handle server error
    }
    return null;
}

export default function ThirdPartyIntegrations(props) {
    const { t } = useTranslation();
    const data = useLoaderData();
    const [search] = useSearchParams();
    const fetcher = useFetcher();
    const { business } = data;
    const shop = search.get('shop');
    const [session, setSession] = useState(business?.shopifySessions?.find((s) => s.shop === shop));
    const [linkedShops, setLinkedShops] = useState(business?.shopifySessions || []);
    const [shopToDisconnect, setShopToDisconnect] = useState(null);
    const [showConnectedDialog, setShowConnectedDialog] = useState(false);
    const { handleError } = useError();

    const handleDidDisconnectShop = () => {
        setShopToDisconnect(null);
        setLinkedShops([]);
    };

    useEffect(() => {
        if (shop && !session?.businessId) {
            setShowConnectedDialog(true);
            const formData = new FormData();
            formData.set('storeName', shop);
            formData.set('businessId', business.businessId);
            formData.set('businessName', business.name);
            formData.set('intent', 'connect');
            fetcher.submit(formData, { method: 'post' });
        }
    }, [shop, session]);

    useEffect(() => {
        if (fetcher.data && fetcher.state === 'idle') {
            setSession(fetcher.data.session);
        }
        if (fetcher.state === 'idle' && fetcher.data?.error) {
            handleError(fetcher.data?.error);
        }
    }, [fetcher]);

    const handleCloseConnectedDialog = () => {
        setShowConnectedDialog(false);
        window.close();
    };

    return (
        <>
            <Stack gap={3} sx={{ maxWidth: '600px' }}>
                <Crumbs />
                <Stack gap={1}>
                    <Stack direction="row" gap={2}>
                        <MapPinIcon sx={{ width: 32, height: 32, color: Teal }} />
                        <Typography variant="displaySm" fontWeight={700}>
                            {t('Integrations.titleLabel')}
                        </Typography>
                    </Stack>
                    <Typography variant="textMd" sx={{ fontWeight: 500 }}>
                        {t('Integrations.promptLabel')}
                    </Typography>
                </Stack>
                {shop ? (
                    <Dialog open={showConnectedDialog}>
                        <DialogTitle>
                            {fetcher.state === 'idle' && session?.businessId
                                ? t('Integrations.connectedDialogTitle')
                                : t('Integrations.connectedDialogPendingTitle')}
                        </DialogTitle>
                        <DialogContent>
                            {session ? (
                                <Typography>
                                    {t('Integrations.connectedDialogDescription', {
                                        shopName: session.businessName,
                                    })}
                                </Typography>
                            ) : null}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseConnectedDialog}>
                                {t('Integrations.connectedDialogClose')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                ) : (
                    <Card>
                        <CardContent>
                            <Stack gap={2}>
                                <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Stack gap={2} direction="row" sx={{ alignItems: 'center' }}>
                                        <Card sx={{ width: 64, height: 64 }}>
                                            <CardContent>
                                                <ShopifyIcon />
                                            </CardContent>
                                        </Card>
                                        <Typography variant="textLg" sx={{ fontWeight: 700 }}>
                                            Shopify
                                        </Typography>
                                    </Stack>
                                    {linkedShops.length < 1 ? (
                                        <Button
                                            href="https://www.shopify.com/store-login"
                                            target="_blank"
                                            variant="contained"
                                            sx={{ height: 40 }}>
                                            {t('Integrations.connectButton')}
                                        </Button>
                                    ) : null}
                                </Stack>
                                <FormInputLabel text={t('Integrations.connectedStoresLabel')}>
                                    <Stack gap={1} sx={{ width: '100%' }}>
                                        {!_.isEmpty(linkedShops) ? (
                                            <Box>
                                                {linkedShops.map((shop, index) => (
                                                    <Stack
                                                        key={index}
                                                        direction="row"
                                                        sx={{
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            pb: '7px',
                                                        }}>
                                                        <Typography variant="textMd" sx={{ fontWeight: 700 }}>
                                                            {shop.shop}
                                                        </Typography>
                                                        <Button
                                                            sx={{ height: 40 }}
                                                            onClick={() => {
                                                                setShopToDisconnect(shop);
                                                            }}
                                                            variant="outlined"
                                                            color="secondary">
                                                            {t('Integrations.disconnectButton')}
                                                        </Button>
                                                    </Stack>
                                                ))}
                                            </Box>
                                        ) : (
                                            <Typography variant="textSm" sx={{ fontWeight: 500 }}>
                                                {t('Integrations.noShopsLabel')}
                                            </Typography>
                                        )}
                                    </Stack>
                                </FormInputLabel>
                            </Stack>
                        </CardContent>
                    </Card>
                )}
            </Stack>
            <DisconnectShopifyModal
                business={business}
                open={shopToDisconnect != null}
                shop={shopToDisconnect}
                onClose={() => setShopToDisconnect(null)}
                onDidDisconnect={handleDidDisconnectShop}
            />
        </>
    );
}
