import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '../../components/LoadingButton/LoadingButton.jsx';
import { redirect, useFetcher, useNavigate } from 'react-router-dom';
import SignInTextBG from '../../components/SignInTextBG/SignInTextBG.jsx';
import { signUp } from 'aws-amplify/auth';
import _ from 'lodash';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormInputLabel from '../../components/FormInputLabel/FormInputLabel.jsx';
import PasswordTextField from '../../components/PasswordTextField/PasswordTextField.jsx';
import List from '@mui/material/List';
import { usePassword } from '../login/PasswordProvider.jsx';
import { validatePasswords } from '../../helpers/HelperFunctions.jsx';
import { useTranslation } from 'react-i18next';
import WholeshopLogoIcon from '../../icons/WholeshopLogoIcon.jsx';

export async function action({ request }) {
    try {
        const formData = await request.formData();
        const username = formData.get('email');
        const password = formData.get('password');
        const lowerCaseUsername = username.toLowerCase();
        const parameters = {
            username: lowerCaseUsername,
            password,
            options: {
                userAttributes: {
                    email: lowerCaseUsername,
                },
                autoSignIn: true,
            },
        };
        const response = await signUp(parameters);
        if (response.nextStep.signUpStep === 'CONFIRM_SIGN_UP') {
            localStorage.setItem('username', lowerCaseUsername);
            return redirect('/signUp/confirm');
        } else {
            return response;
        }
    } catch (error) {
        return {
            error: error.message,
        };
    }
}

export const mapErrorMessage = (error) => {
    if (error.toString().includes('EmptySignInUsername')) {
        return 'Email required';
    } else if (error.toString().includes('EmptySignInPassword')) {
        return 'Password required';
    } else {
        return 'Sign up failed, please try again.';
    }
};

export default function SignUp() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordErrors, setPasswordErrors] = useState([]);
    const fetcher = useFetcher();
    const navigate = useNavigate();
    const isSaving = fetcher.state === 'submitting';
    const { setPassword: setPersistentPassword } = usePassword();
    const { t } = useTranslation();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPersistentPassword(newPassword);
        setPassword(newPassword);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const submitButtonEnabled = () => {
        return !_.isEmpty(email) && !_.isEmpty(password) && !_.isEmpty(confirmPassword) && _.isEmpty(passwordErrors);
    };

    const handleSignInSelect = () => {
        navigate('/login');
    };

    useEffect(() => {
        setPasswordErrors(validatePasswords({ password, confirmPassword }));
    }, [password, confirmPassword]);

    return (
        <Stack direction="row" sx={{ height: '100vh', width: '100%' }}>
            <Stack
                sx={{
                    flex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 10,
                    mx: 5,
                }}>
                <fetcher.Form method="POST" style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <Stack gap={5} sx={{ alignItems: 'flex-start', flexGrow: 1 }}>
                        <WholeshopLogoIcon />
                        <Stack gap={3}>
                            <Stack>
                                <Typography variant="displayMd">{t('SignUp.createAccountLabel')}</Typography>
                                <Typography sx={{ color: 'common.LightGray' }}>{t('SignUp.signUpPrompt')}</Typography>
                            </Stack>
                            <FormInputLabel text={t('SignUp.userEmailLabel')}>
                                <TextField
                                    fullWidth
                                    name="email"
                                    placeholder="Enter email address"
                                    value={email}
                                    onChange={handleEmailChange}
                                    autoComplete="new-username"
                                />
                            </FormInputLabel>
                            <FormInputLabel text={t('SignUp.passwordLabel')}>
                                <PasswordTextField
                                    fullWidth
                                    name="password"
                                    placeholder="Create password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    autoComplete="new-password"
                                />
                            </FormInputLabel>
                            {passwordErrors.length > 0 ? (
                                <List>
                                    {passwordErrors.map((error, index) => (
                                        <Typography sx={{ color: 'common.Teal' }} key={index}>
                                            {error}
                                        </Typography>
                                    ))}
                                </List>
                            ) : null}
                            <FormInputLabel text={t('SignUp.confirmPasswordLabel')}>
                                <PasswordTextField
                                    fullWidth
                                    name="confirmPassword"
                                    label="CONFIRM PASSWORD"
                                    placeholder="Confirm password"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    autoComplete="confirm-password"
                                />
                            </FormInputLabel>
                            {fetcher.data?.error ? (
                                <Typography
                                    sx={{
                                        color: 'common.Alert',
                                    }}>{`Error: ${mapErrorMessage(fetcher.data.error)}`}</Typography>
                            ) : null}
                            <Stack gap={1}>
                                <LoadingButton
                                    disabled={!submitButtonEnabled()}
                                    isLoading={isSaving}
                                    loadingColor="white"
                                    type="submit"
                                    variant="contained"
                                    color="primary">
                                    {t('SignUp.createAccountLabel')}
                                </LoadingButton>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="row"
                        sx={{
                            mt: 'auto',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: 2,
                            paddingBottom: '20px',
                        }}>
                        <Typography sx={{ color: 'common.Teal' }}>{t('SignUp.haveAccountLabel')}</Typography>
                        <Button
                            onClick={handleSignInSelect}
                            variant="underlined"
                            sx={{
                                color: 'common.Midnight',
                                padding: '4px 4px',
                                minWidth: 'auto',
                                fontSize: '16px',
                            }}>
                            {t('SignUp.signInButton')}
                        </Button>
                    </Stack>
                </fetcher.Form>
            </Stack>
            <Box
                sx={{
                    flex: 1,
                    display: {
                        xs: 'none',
                        sm: 'block',
                    },
                }}>
                <SignInTextBG />
            </Box>
        </Stack>
    );
}
