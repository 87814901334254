import { Authenticator } from '@aws-amplify/ui-react';
import { t } from 'i18next';
import * as React from 'react';
import { createBrowserRouter, Link, Outlet } from 'react-router-dom';
import { BusinessTypes } from '../constants/BusinessTypes';
import { awaitAuth } from '../providers/auth';
import { BrandRoutes } from './brand/BrandRoutes.jsx';
import BusinessSetup, { loader as businessSetupLoader, action as submitBusinessSetup } from './business/BusinessSetup';
import BusinessSetupStripe, { loader as businessSetupStripeLoader } from './business/BusinessSetupStripe';
import BusinessSetupSuccess, { loader as businessSetupSuccessLoader } from './business/BusinessSetupSuccess';
import Cart from './cart/Cart.jsx';
import CartCheckout, { action as cartCheckoutAction, loader as cartLoader } from './cart/CartCheckout.jsx';
import CartCheckoutError from './cart/CartCheckoutError.jsx';
import InviteSignup, { action as inviteSignUpAction } from './InviteSignup/InviteSignup.jsx';
import { action as inviteAction } from './settings/Invite/Invite.jsx';
import LinkBusiness, { loader as linkBusinessLoader } from './LinkBusiness/LinkBusiness.jsx';
import Login, { loader as loginLoader, action as loginAction } from './login/Login';
import { action as logoutAction } from './logout/Logout';
import PrivacyPolicy from './PrivacyPolicy';
import Connections, {
    loader as connectionsLoader,
    routeId as connectionsRouteId,
} from './retail/connections/Connections';
import BusinessDetailsLayout, {
    loader as businessDetailsLayoutLoader,
} from './retail/connections/products/BusinessDetailsLayout.jsx';
import ConnectedBrandProducts, {
    loader as connectedBrandProductsLoader,
} from './retail/connections/products/ConnectedBrandProducts';
import ProductDetails, { loader as productDetailsLoader } from './retail/connections/products/ProductDetails';
import RetailOrderDetails, {
    action as retailOrderDetailsAction,
    loader as retailOrderDetailsLoader,
} from './retail/orders/RetailOrderDetails.jsx';
import RetailOrders, { loader as retailOrderLoader } from './retail/orders/RetailOrders.jsx';
import { loader as retailLoader } from './retail/retail.loader.js';
import RetailLayout from './retail/RetailLayout.jsx';
import Root, { loader as rootLoader } from './root/Root';
import RootBoundary from './root/RootBoundary';
import Settings, { loader as settingsLoader } from './settings/Settings.jsx';
import { SettingsRoutes } from './settings/SettingsRoutes.jsx';
import TermsOfUse from './TermsOfUse';
import Buttons from './retail/Buttons.jsx';
import { ThemeProvider } from '@mui/material/styles';
import retailTheme from '../theme.retail.js';
import SignUp, { action as signUpAction } from './SignUp/SignUp.jsx';
import ConfirmSignUp, { action as confirmSignUpAction } from './SignUp/ConfirmSignUp.jsx';
import ForgotPassword, { action as forgotPasswordAction } from './ForgotPassword/ForgotPassword.jsx';
import ConfirmPasswordReset, { action as confirmPasswordResetAction } from './ForgotPassword/ConfirmPasswordReset.jsx';
import { PasswordProvider } from './login/PasswordProvider.jsx';
import CreateNewPassword, { action as newPasswordAction } from './CreateNewPassword/CreateNewPassword.jsx';
import UserProfile, {
    loader as userProfileLoader,
    action as userProfileAction,
} from './settings/UserProfile/UserProfile.jsx';
import { action as disconnectShopifyAction } from '../components/DisconnectShopifyModal/DisconnectShopifyModal.jsx';
import { action as settingsAction } from './settings/SettingsContext.jsx';
import LandingPage from './LandingPage/LandingPage.jsx';
import AboutPage from './AboutPage/AboutPage.jsx';
import NewsPage from './NewsPage/NewsPage.jsx';

export default createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        loader: rootLoader,
        errorElement: <RootBoundary />,
        id: 'root',
        children: [
            {
                path: '',
                index: true,
                id: 'LandingPage',
                element: (
                    <ThemeProvider theme={retailTheme}>
                        <LandingPage />
                    </ThemeProvider>
                ),
            },
            {
                path: '/about',
                index: true,
                id: 'About',
                element: (
                    <ThemeProvider theme={retailTheme}>
                        <AboutPage />
                    </ThemeProvider>
                ),
            },
            {
                path: '/news',
                index: true,
                id: 'News',
                element: (
                    <ThemeProvider theme={retailTheme}>
                        <NewsPage />
                    </ThemeProvider>
                ),
            },
            {
                path: 'buttons',
                element: <Buttons />,
            },
            {
                path: 'invite',
                action: inviteAction,
            },
            BrandRoutes(),
            {
                path: 'brand/settings',
                element: <Settings type={BusinessTypes.BRAND} />,
                children: SettingsRoutes(BusinessTypes.BRAND),
                action: settingsAction,
                loader: awaitAuth(settingsLoader),
            },
            {
                path: 'retail',
                loader: awaitAuth(retailLoader),
                element: (
                    <ThemeProvider theme={retailTheme}>
                        <Outlet />
                    </ThemeProvider>
                ),
                children: [
                    {
                        path: 'user-profile',
                        loader: awaitAuth(userProfileLoader),
                        action: userProfileAction,
                        element: <UserProfile />,
                    },
                    {
                        path: 'connections/',
                        element: <Outlet />,
                        loader: awaitAuth(connectionsLoader),
                        id: connectionsRouteId,
                        children: [
                            {
                                path: '',
                                index: true,
                                id: 'ConnectionsView',
                                element: <Connections routeId={connectionsRouteId} businessType={'Retail'} />,
                            },
                            {
                                id: 'BusinessLandingPage',
                                path: ':id/',
                                element: <BusinessDetailsLayout />,
                                loader: awaitAuth(businessDetailsLayoutLoader),
                                handle: {
                                    crumb: ({ data, params }) => (
                                        // TODO link to landing page instead of products when implemented
                                        <Link key="business-home" to={`/retail/connections/${params.id}/products`}>
                                            {data?.business?.name}
                                        </Link>
                                    ),
                                },
                                children: [
                                    {
                                        path: 'products/',
                                        element: <Outlet />,
                                        handle: {
                                            crumb: ({ params }) => (
                                                <Link
                                                    key="business-product-details"
                                                    to={`/retail/connections/${params.id}/products`}>
                                                    {t('OurAppBar.productsLabel')}
                                                </Link>
                                            ),
                                        },
                                        children: [
                                            {
                                                id: 'business-products',
                                                path: '',
                                                index: true,
                                                element: <ConnectedBrandProducts />,
                                                loader: awaitAuth(connectedBrandProductsLoader),
                                            },
                                            {
                                                id: 'retail-product-details',
                                                path: ':productId',
                                                element: <ProductDetails />,
                                                loader: awaitAuth(productDetailsLoader),
                                            },
                                        ],
                                    },
                                    {
                                        id: 'business-cart',
                                        path: 'cart',
                                        element: <Cart />,
                                        loader: awaitAuth(cartLoader),
                                    },
                                    {
                                        id: 'business-cart-checkout',
                                        path: 'cart/checkout',
                                        element: <CartCheckout />,
                                        loader: awaitAuth(cartLoader),
                                        action: cartCheckoutAction,
                                        errorElement: <CartCheckoutError />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'messages',
                        element: <>messages</>,
                    },
                    {
                        path: 'reports',
                        element: <>reports</>,
                    },
                    {
                        id: 'retail-orders',
                        path: 'orders/',
                        element: <RetailLayout />,
                        loader: awaitAuth(retailOrderLoader),
                        children: [
                            {
                                path: '',
                                index: true,
                                element: <RetailOrders />,
                            },
                            {
                                path: ':id',
                                element: <RetailOrderDetails />,
                                loader: awaitAuth(retailOrderDetailsLoader),
                                action: retailOrderDetailsAction,
                            },
                        ],
                    },
                    {
                        path: 'settings',
                        element: <Settings type={BusinessTypes.RETAIL} />,
                        children: SettingsRoutes(BusinessTypes.RETAIL),
                        action: settingsAction,
                        loader: awaitAuth(settingsLoader),
                    },
                ],
            },
        ],
    },
    {
        path: '/business/setup',
        element: (
            <ThemeProvider theme={retailTheme}>
                <BusinessSetup />
            </ThemeProvider>
        ),
        loader: businessSetupLoader,
        action: submitBusinessSetup,
    },
    {
        path: '/business/setup/stripe',
        element: <BusinessSetupStripe />,
        loader: awaitAuth(businessSetupStripeLoader),
    },
    {
        path: '/business/setup/success',
        element: (
            <ThemeProvider theme={retailTheme}>
                <BusinessSetupSuccess />
            </ThemeProvider>
        ),
        loader: awaitAuth(businessSetupSuccessLoader),
    },
    {
        path: '/signUp',
        action: signUpAction,
        element: (
            <Authenticator.Provider>
                <PasswordProvider>
                    <ThemeProvider theme={retailTheme}>
                        <Outlet />
                    </ThemeProvider>
                </PasswordProvider>
            </Authenticator.Provider>
        ),
        children: [
            {
                path: '',
                element: <SignUp />,
            },
            {
                path: 'confirm',
                action: confirmSignUpAction,
                element: <ConfirmSignUp />,
            },
        ],
    },
    {
        path: '/forgotPassword',
        action: forgotPasswordAction,
        element: (
            <Authenticator.Provider>
                <ThemeProvider theme={retailTheme}>
                    <Outlet />
                </ThemeProvider>
            </Authenticator.Provider>
        ),
        children: [
            {
                path: '',
                action: forgotPasswordAction,
                element: <ForgotPassword />,
                loader: loginLoader,
            },
            {
                path: 'confirm',
                action: confirmPasswordResetAction,
                element: <ConfirmPasswordReset />,
            },
        ],
    },
    {
        path: '/login',
        action: loginAction,
        element: (
            <Authenticator.Provider>
                <PasswordProvider>
                    <ThemeProvider theme={retailTheme}>
                        <Outlet />
                    </ThemeProvider>
                </PasswordProvider>
            </Authenticator.Provider>
        ),
        loader: loginLoader,
        children: [
            {
                path: '',
                element: <Login />,
            },
            {
                path: 'newPassword',
                element: <CreateNewPassword />,
                action: newPasswordAction,
            },
        ],
    },
    {
        path: '/linkNewBusiness',
        action: inviteSignUpAction,
        element: (
            <ThemeProvider theme={retailTheme}>
                <InviteSignup />
            </ThemeProvider>
        ),
    },
    {
        path: '/linkExistingBusiness',
        element: <LinkBusiness />,
        loader: linkBusinessLoader,
    },
    { path: '/logout', action: logoutAction },
    { path: '/shopify/disconnect', action: disconnectShopifyAction },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
    },
    {
        path: '/terms-of-use',
        element: <TermsOfUse />,
    },
]);
