import React, { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { useBlocker, useLoaderData, useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import GeneralProductInfo from './GeneralProductInformation/GeneralProductInfo';
import Pricing from './GeneralProductInformation/Pricing';
import Categories from './GeneralProductInformation/Categories.jsx';
import Media from './BrandMedia/Media';
import { put } from '../../../providers/fetchUtil';
import ProductVariants from './ProductVariants/ProductVariants.jsx';
import _ from 'lodash';
import {
    checkRequiredFieldValues,
    cleanData,
    findDifferences,
    requiredFields,
    revertTagColumnsToTags,
    productShape,
} from './ProductVariants/ProductVariantTable/productUtil.js';
import * as api from '../../../api.js';
import { useError } from '../../../components/Error/ErrorProvider.jsx';
import PropTypes from 'prop-types';
import EditCardWrapper from '../../../components/EditCardWrapper/EditCardWrapper.jsx';
import ProductSpecsTable from './ProductSpecs/ProductSpecsTable.jsx';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import BrandProductEditButtons from '../../../components/BrandProductEditButtons/BrandProductEditButtons.jsx';
import AbandonProductModal from './ProductModals/AbandonProductModal.jsx';
import MissingFieldsModal from './ProductModals/MissingFieldsModal.jsx';
import ArchiveProductModal from './ProductModals/ArchiveProductModal.jsx';
import BackButton from '../../../components/BackButton/BackButton.jsx';
import ProductIDs from './GeneralProductInformation/ProductIDs.jsx';

export default function BrandProduct({ isCreateProduct = false }) {
    const { business } = useRouteLoaderData('root') || {};
    let { product = productShape } = useLoaderData() || {};
    const { productId } = useParams();
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [newProduct, setNewProduct] = useState(product);
    const [fieldsValidity, setFieldsValidity] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const { handleError } = useError();
    const [missingFieldsModalOpen, setMissingFieldsModalOpen] = useState(false);
    const blockerEnabled = useRef(isCreateProduct);
    const blocker = useBlocker(() => hasMadeChanges() && blockerEnabled.current);
    const hasMadeChanges = () => {
        // This is a somewhat complex compare function. When product variants are refactored, this will probably need to be removed or looked at.
        const adjustedNewProduct = _.cloneDeep(newProduct);
        adjustedNewProduct.productVariants = revertTagColumnsToTags(
            adjustedNewProduct.productVariants,
            adjustedNewProduct.productVariantTagOptions
        );
        const changesAtProductLevel = findDifferences(adjustedNewProduct, product, [
            'productVariants',
            'productVariantTagOptions',
        ]);
        const changesAtVariantLevel = findDifferences(adjustedNewProduct.productVariants, product.productVariants, [
            'description',
            'createdAt',
            'updatedAt',
            'productId',
        ]);

        return !_.isEmpty(changesAtProductLevel) || !_.isEmpty(changesAtVariantLevel);
    };

    function handleValidity(e) {
        const v = e?.target?.value;
        const newValidity = { ...fieldsValidity };
        setFieldsValidity(_.set(newValidity, e?.target?.name, _.isEmpty(v)));
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const productClone = _.cloneDeep(newProduct);
        // if there is only one PV set the field values using product form
        if (productClone.productVariants.length === 1) {
            const singlePV = productClone.productVariants[0];
            singlePV[name] = value;
        }
        productClone[name] = value;
        setNewProduct(productClone);
    };

    const handleVariantChange = (val) => {
        const copyOfNewProduct = _.cloneDeep(newProduct);
        copyOfNewProduct.productVariants = val;
        setNewProduct(copyOfNewProduct);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const rq = checkRequiredFieldValues(newProduct, requiredFields);
        const hasErrors = _.keys(_.pickBy(rq, _.identity));
        setFieldsValidity(rq);

        if (!_.isEmpty(hasErrors)) {
            setMissingFieldsModalOpen(true);
            return;
        }

        // Check for missing spec values
        if (newProduct.specs.some((item) => item.name.trim() === '' || item.value.trim() === '')) {
            setSnackbarMessage('Missing required fields in Specs');
            setSnackbarType('error');
            setSnackbarOpen(true);
            return; // Stop the form submission process
        }

        if (newProduct.title.length > 255) {
            setSnackbarMessage('Product name is too long. Please keep product names to 255 characters or fewer.');
            setSnackbarType('error');
            setSnackbarOpen(true);
            return; // Stop the form submission process
        }

        let productData = {
            ...newProduct,
            productVariants: revertTagColumnsToTags(newProduct.productVariants, newProduct.productVariantTagOptions),
            imageLinks: newProduct.imageLinks || [],
            businessId: business.businessId,
            specs: newProduct.specs,
            active: true,
        };
        delete productData.productVariantTagOptions;

        productData = cleanData(productData);

        setIsSubmitting(true);
        try {
            // Use POST for create and PUT for update based on the presence of productId
            const url = productId ? `/product/${productId}` : '/product';
            const payload = productData;
            const response = productId ? await put(url, payload) : await api.createProduct(payload, {});
            const data = await response.json();

            if (!response.ok) {
                throw new Error(`Error ${response.status}: ${data.message || 'Failed to save product data'}`);
            }
            // disable blocker
            blockerEnabled.current = false;
            setSnackbarMessage('Product data saved successfully');
            setSnackbarType('success');
            setSnackbarOpen(true);
            product = newProduct;
            navigate('/brand/products');
        } catch (error) {
            setSnackbarMessage(error.message);
            setSnackbarType('error');
            setSnackbarOpen(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSpecsChange = (updatedSpecs) => {
        const newProductCopy = _.cloneDeep(newProduct);
        newProductCopy.specs = updatedSpecs;
        newProductCopy.productVariants = newProductCopy.productVariants.map((variant) => {
            return {
                ...variant,
                specs: updatedSpecs,
            };
        });
        setNewProduct(newProductCopy);
    };

    const handleImagesChange = (updatedImages) => {
        const newProductCopy = _.cloneDeep(newProduct);
        newProductCopy.imageLinks = updatedImages;
        newProductCopy.productVariants = newProductCopy.productVariants.map((variant) => {
            return {
                ...variant,
                imageLinks: updatedImages,
            };
        });
        setNewProduct(newProductCopy);
    };

    const handleOrganizationChange = (updatedCategories) => {
        if (!_.isEmpty(updatedCategories)) {
            const newProductCopy = _.cloneDeep(newProduct);
            newProductCopy.categories = updatedCategories;
            setNewProduct(newProductCopy);
        }
    };

    const handleDeleteProduct = async () => {
        try {
            setIsDeleting(true);
            const productClone = _.cloneDeep(product);
            productClone.active = false;
            const variants = revertTagColumnsToTags(
                productClone.productVariants,
                productClone.productVariantTagOptions
            );

            productClone.productVariants = variants;
            delete productClone.productVariantTagOptions;

            await api.updateProduct(product.id, productClone, {});
            blockerEnabled.current = false;
            navigate('/brand/products');
        } catch (error) {
            const a = await error.json();
            handleError({ message: a?.message });
        } finally {
            setIsDeleting(false);
            setShowDeleteModel(false);
        }
    };

    function handlePvOptionsChange(options) {
        const newProductCopy = _.cloneDeep(newProduct);
        newProductCopy.productVariantTagOptions = options;
        setNewProduct(newProductCopy);
    }

    return (
        <>
            <Stack sx={{ pb: 'calc(40vh)' }}>
                <Stack spacing={1}>
                    <BackButton />
                    <BrandProductEditButtons
                        isNewProduct={product?.id === ''}
                        isSubmitting={isSubmitting}
                        productChanged={hasMadeChanges()}
                        onSave={handleSubmit}
                        onRemove={() => setShowDeleteModel(true)}
                    />
                </Stack>
                <Container>
                    <Box sx={{ flexGrow: 1, padding: 2 }}>
                        <EditCardWrapper title={newProduct?.id ? 'Edit Product' : 'New Product'}>
                            <GeneralProductInfo
                                formValues={newProduct}
                                handleValidity={handleValidity}
                                fieldsValidity={fieldsValidity}
                                handleInputChange={handleInputChange}
                            />
                        </EditCardWrapper>
                        <EditCardWrapper title={'Pricing'}>
                            <Pricing
                                product={newProduct}
                                onChange={handleInputChange}
                                handleValidity={handleValidity}
                                fieldsValidity={fieldsValidity}
                            />
                        </EditCardWrapper>

                        <EditCardWrapper title={'Organization'}>
                            <Categories
                                categories={newProduct.categories}
                                handleInputChange={handleOrganizationChange}
                                handleValidity={handleValidity}
                                fieldsValidity={fieldsValidity}
                            />
                        </EditCardWrapper>

                        {newProduct.productVariants.length < 2 ? (
                            <EditCardWrapper title={'Product IDs'}>
                                <ProductIDs
                                    product={newProduct}
                                    onChange={handleInputChange}
                                    handleValidity={handleValidity}
                                    fieldsValidity={fieldsValidity}
                                />
                            </EditCardWrapper>
                        ) : null}

                        <EditCardWrapper title={'Media'}>
                            <Media images={newProduct.imageLinks} handleImagesChange={handleImagesChange} />
                        </EditCardWrapper>

                        <EditCardWrapper title={'Specs'}>
                            <ProductSpecsTable specs={product.specs} updateSpecs={handleSpecsChange} />
                        </EditCardWrapper>

                        <EditCardWrapper title={'Variants'}>
                            <ProductVariants
                                setFieldsValidity={setFieldsValidity}
                                handleInputChange={handleInputChange}
                                product={newProduct}
                                onPVOptionsChange={handlePvOptionsChange}
                                onChange={handleVariantChange}
                            />
                        </EditCardWrapper>
                        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                            <Alert
                                onClose={() => setSnackbarOpen(false)}
                                severity={snackbarType}
                                sx={{ width: '100%' }}>
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>
                    </Box>
                </Container>
            </Stack>
            <AbandonProductModal
                open={blocker.state === 'blocked' && blockerEnabled && isCreateProduct}
                onClose={() => blocker.reset()}
                onLeave={blocker.proceed}
            />
            <MissingFieldsModal
                open={missingFieldsModalOpen}
                missingFields={checkRequiredFieldValues(newProduct, requiredFields)}
                onClose={() => setMissingFieldsModalOpen(false)}
            />
            <ArchiveProductModal
                open={showDeleteModel}
                onClose={() => setShowDeleteModel(false)}
                onArchive={handleDeleteProduct}
                isArchiving={isDeleting}
            />
        </>
    );
}

BrandProduct.propTypes = {
    isCreateProduct: PropTypes.bool,
};
