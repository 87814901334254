import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

function OrderSortSelect(props) {
    const { t } = useTranslation();
    const orderSortOptions = [
        { value: '-createdAt', label: t('OrderSortSelect.newestFirst') },
        { value: 'createdAt', label: t('OrderSortSelect.oldestFirst') },
    ];
    const { label, onChange, selectProps, defaultValue = '' } = props;
    const [value, setValue] = React.useState(defaultValue || orderSortOptions[0].value);

    const handleChange = (event) => {
        setValue(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };
    return (
        <Box sx={{ maxWidth: 160 }}>
            <FormControl fullWidth>
                <InputLabel id="order-sort-select-label">{label}</InputLabel>
                <Select
                    label={label}
                    labelId="order-sort-select-label"
                    id="order-sort-select"
                    value={value}
                    onChange={handleChange}
                    {...selectProps}>
                    {orderSortOptions.map((i) => (
                        <MenuItem key={i.value} value={i.value}>
                            <Typography
                                variant="textTiny"
                                sx={{ textTransform: 'uppercase', fontWeight: 'bold', pt: 0.5 }}>
                                {i.label}
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

OrderSortSelect.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    selectProps: PropTypes.any,
    defaultValue: PropTypes.string,
};

export default OrderSortSelect;
