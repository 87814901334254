import * as React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CurrencyInput from '../../../../components/CurrencyInput/CurrencyInput';

const Pricing = ({ product, onChange, fieldsValidity, handleValidity }) => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <CurrencyInput
                    error={fieldsValidity?.msrp}
                    onBlur={handleValidity}
                    label={t('Brand.ProductForm.Pricing.msrpLabel')}
                    name="msrp"
                    fullWidth
                    value={product?.msrp}
                    onChange={onChange}
                    required
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <CurrencyInput
                    error={fieldsValidity?.wholesale}
                    onBlur={handleValidity}
                    label={t('Brand.ProductForm.Pricing.wholesaleLabel')}
                    name="wholesale"
                    fullWidth
                    value={product?.wholesale}
                    onChange={onChange}
                    required
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <CurrencyInput
                    label={t('Brand.ProductForm.Pricing.retailDefaultPriceLabel')}
                    name="retailPrice"
                    fullWidth
                    value={product?.retailPrice}
                    onChange={onChange}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <CurrencyInput
                    label={t('Brand.ProductForm.Pricing.mapPricingLabel')}
                    name="minimumAdvertisePrice"
                    fullWidth
                    value={product?.minimumAdvertisePrice}
                    onChange={onChange}
                />
            </Grid>
        </Grid>
    );
};

Pricing.propTypes = {
    product: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    handleValidity: PropTypes.func.isRequired,
    fieldsValidity: PropTypes.object,
};

export default Pricing;
