import React, { useState, useEffect } from 'react';
import BrandProduct from '../BrandProduct';
import * as api from '../../../../api.js';
import * as Sentry from '@sentry/react';

export async function loader() {
    try {
        const allCategory = await api.getCategories('top');
        return {
            allCategory,
            products: [],
        };
    } catch (error) {
        Sentry.captureException(error);
        return { error };
    }
}

export default function BrandCreateProducts() {
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const result = await loader();
            if (result.error) {
                setError(result.error);
            }
        }
        fetchData();
    }, []);

    if (error) {
        // Render error UI
        return <div>An error occurred. Please try again later.</div>;
    }

    return <BrandProduct isCreateProduct={true} />;
}
