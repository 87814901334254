import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Fog, GridZebraStripingGrey, Tide, White } from '../../theme';

const Cell = ({ isSticky, className, column, columnIndex, row, rowIndex, group, groupIndex, sx = {} }) => (
    <Box className={`group-row-cell${className ? ' ' + className : ''}${isSticky ? ' sticky' : ''}`} sx={sx}>
        {column.renderCell ? (
            column.renderCell({
                column: { data: column, index: columnIndex },
                row: { data: row, index: rowIndex },
                group: { data: group, index: groupIndex },
            })
        ) : (
            <Typography
                variant="textXs"
                sx={{ fontWeight: 500, lineHeight: '30px' }}
                title={row?.variantTags?.find((tag) => tag.name === column.field)?.value}>
                {row?.variantTags?.find((tag) => tag.name === column.field)?.value}
            </Typography>
        )}
    </Box>
);

Cell.propTypes = {
    className: PropTypes.string,
    column: PropTypes.object.isRequired,
    columnIndex: PropTypes.number.isRequired,
    isSticky: PropTypes.bool,
    row: PropTypes.object.isRequired,
    rowIndex: PropTypes.number.isRequired,
    group: PropTypes.object.isRequired,
    groupIndex: PropTypes.number.isRequired,
    sx: PropTypes.object,
};

export default function GroupedDataGrid(props) {
    const { columns = [], rows: groups = [], sx = {} } = props;
    const theme = useTheme();
    const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'));
    const shouldShowStickyColumns = columns.some((column) => column.isStickyColumn) && columns.length > 3;
    const gridTemplateColumns =
        isMobileWidth && shouldShowStickyColumns
            ? columns
                  .map(() => '88px')
                  .slice(0, -1)
                  .join(' ') + ' 65px'
            : columns
                  .map(() => '1fr')
                  .slice(0, -1)
                  .join(' ') + ' 32px';

    return (
        <Box
            sx={{
                '& .sticky': {
                    display: { xs: 'inline-block', sm: 'initial' },
                    position: { xs: 'sticky', sm: 'initial' },
                    right: { xs: 0, sm: 'initial' },
                    zIndex: { xs: 1, sm: 'initial' },
                    bgcolor: { xs: 'inherit' },
                    borderLeft: { xs: shouldShowStickyColumns ? `1px solid ${Fog}` : 'none', sm: 'none' },
                    my: -1,
                    py: 1,
                    '& > div': {
                        justifyContent: { xs: 'center', sm: 'flex-end' },
                    },
                },
                ...sx,
            }}>
            <Box
                sx={{
                    borderBottom: `1px solid ${Fog}`,
                    bgcolor: White,
                    px: 3,
                    py: 1,
                    pr: { xs: shouldShowStickyColumns ? 0 : 3, sm: 3 },
                    display: 'grid',
                    gridTemplateColumns,
                    gap: 1,
                    position: 'sticky',
                    top: 0,
                    zIndex: 10,
                    '& .header': {
                        '&:first-of-type': {
                            textAlign: 'left',
                        },
                        '&:last-of-type': {
                            textAlign: 'right',
                        },
                        '&.sticky': {
                            borderBottom: { xs: `1px solid ${Fog}`, sm: 'none' },
                            mb: '-9px',
                            textAlign: { xs: 'center' },
                        },
                    },
                }}>
                {columns.map((column) => (
                    <Typography
                        className={`header${column.isStickyColumn ? ' sticky' : ''}`}
                        key={column.field}
                        variant="label"
                        sx={{ color: Tide, whiteSpace: 'nowrap' }}>
                        {column.headerName}
                    </Typography>
                ))}
            </Box>
            {groups.map((group, groupIndex) => (
                <Box
                    key={groupIndex}
                    className="group-row"
                    sx={{
                        px: 3,
                        py: 1,
                        display: 'grid',
                        gridTemplateColumns,
                        gap: 1,
                        '&:nth-of-type(even)': {
                            backgroundColor: White,
                        },
                        '&:nth-of-type(odd)': {
                            backgroundColor: GridZebraStripingGrey,
                        },
                        '& .group-row-cell:not(:first-of-type)': {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            '& p': {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            },
                        },
                    }}>
                    {group.rows.map((row, rowIndex) =>
                        columns.map((column, columnIndex) =>
                            column.rowHeader && rowIndex > 0 ? null : (
                                <Cell
                                    isSticky={shouldShowStickyColumns && column.isStickyColumn}
                                    sx={column.rowHeader ? { gridRow: `span ${group.rows.length}` } : {}}
                                    key={`${rowIndex}-${columnIndex}`}
                                    column={column}
                                    columnIndex={columnIndex}
                                    row={row}
                                    rowIndex={rowIndex}
                                    group={group}
                                    groupIndex={groupIndex}
                                />
                            )
                        )
                    )}
                </Box>
            ))}
        </Box>
    );
}

GroupedDataGrid.propTypes = {
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    sx: PropTypes.object,
};
