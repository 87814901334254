import Box from '@mui/material/Box';
import React from 'react';
import * as api from '../../../../api';
import * as Sentry from '@sentry/react';

export async function loader({ signal, params }) {
    try {
        const [product, allCategory] = await Promise.all([
            api.getProductById(params.productId, { signal }),
            api.getCategories('top', { signal }),
        ]);
        return {
            allCategory,
            product,
        };
    } catch (error) {
        Sentry.captureException(error);
        console.error('Failed to load data:', error);
        throw error;
    }
}

export default function BrandProductDetails() {
    return <Box sx={{ mb: 4, mt: 8 }}></Box>;
}
