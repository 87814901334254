import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import {
    checkRequiredFieldValues,
    cleanData,
    cleanVariants,
    requiredFields,
    productShape,
} from '../ProductVariantTable/productUtil.js';
import { post, put } from '../../../../../providers/fetchUtil.js';
import Stack from '@mui/material/Stack';
import BackButton from '../../../../../components/BackButton/BackButton.jsx';
import BrandProductEditButtons from '../../../../../components/BrandProductEditButtons/BrandProductEditButtons.jsx';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MissingFieldsModal from '../../ProductModals/MissingFieldsModal.jsx';
import ProductVariantEditPage from './ProductVariantEditPage/ProductVariantEditPage.jsx';
import Box from '@mui/material/Box';
import EditProductVariantSelect from './ProductVariantEditPage/EditProductVariantSelect/EditProductVariantSelect.jsx';
import EditCardWrapper from '../../../../../components/EditCardWrapper/EditCardWrapper.jsx';
import { useTranslation } from 'react-i18next';
import ProductIcon from '../../../../../components/ProductIcon.jsx';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';

export default function ProductVariantEdit() {
    let { product = productShape } = useLoaderData() || {};
    const { productId } = useParams();
    // Arrange the variants to have default first in array
    const [newProduct, setNewProduct] = useState(() => ({
        ...product,
        productVariants: product.productVariants.sort((a, b) => (b.isDefault ? 1 : -1)),
    }));
    const [variantFieldsValidity, setVariantFieldsValidity] = useState([]);
    const [hideInactive, setHideInactive] = useState(true);
    const [selectedVariantIndex, setSelectedVariantIndex] = useState(
        newProduct.productVariants.findIndex((variant) => variant.isDefault === true) ?? 0
    );
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [missingFieldsModalOpen, setMissingFieldsModalOpen] = useState(false);
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const { t } = useTranslation();
    const showText = hideInactive ? t('BrandProductTable.showArchived') : t('BrandProductTable.hideArchived');
    const filteredVisibleVariants = () => {
        return newProduct.productVariants.filter((variant) => variant.active === hideInactive);
    };

    const handleNewVariantSelected = (index) => {
        const selectedVariantId = filteredVisibleVariants()[index]?.id;
        const selectedIndex = newProduct.productVariants.findIndex((variant) => variant.id === selectedVariantId);
        if (selectedIndex !== -1) {
            setSelectedVariantIndex(selectedIndex);
        }
    };

    useEffect(() => {
        handleNewVariantSelected(0);
    }, [hideInactive]);

    const handleVariantChange = (updatedVariant) => {
        setNewProduct((prevProduct) => ({
            ...prevProduct,
            productVariants: prevProduct.productVariants.map((variant) =>
                variant.id === updatedVariant.id
                    ? { ...variant, ...updatedVariant }
                    : { ...variant, isDefault: updatedVariant.isDefault ? false : variant.isDefault }
            ),
        }));
    };

    function checkRequiredFieldValuesVariants(product) {
        const variants = product?.productVariants || [];
        const req = variants.reduce((acc, variant) => {
            const invalid = checkRequiredFieldValues(variant, requiredFields);
            const name = variant.variantTags.map((attr) => attr.value).join(' / ');
            acc[name] = invalid;
            return acc;
        }, {});

        const flatten = _.transform(
            req,
            (result, value, key) => {
                _.forOwn(value, (subValue, subKey) => {
                    if (subValue === true) {
                        result[`${key}: ${subKey}`] = true;
                    }
                });
            },
            {}
        );

        return flatten;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const rq = checkRequiredFieldValuesVariants(newProduct);
        const hasErrors = _.keys(_.pickBy(rq, _.identity));

        if (!_.isEmpty(hasErrors)) {
            setMissingFieldsModalOpen(true);
            return;
        }

        let productData = {
            ...newProduct,
        };
        delete productData.productVariantTagOptions;

        productData.productVariants = cleanVariants(productData?.productVariants);

        productData = cleanData(productData);
        setIsSubmitting(true);

        try {
            // Use POST for create and PUT for update based on the presence of productId
            const url = productId ? `/product/${productId}` : '/product';
            const payload = productData;
            const response = productId ? await put(url, payload) : await post(url, payload);
            const data = await response.json();

            if (!response.ok) {
                throw new Error(`Error ${response.status}: ${data.message || 'Failed to save product data'}`);
            }

            setSnackbarMessage('Product Variants Saved Successfully');
            setSnackbarType('success');
            setSnackbarOpen(true);
            product = newProduct;
            navigate('/brand/products');
        } catch (error) {
            setSnackbarMessage(error.message);
            setSnackbarType('error');
            setSnackbarOpen(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    function handleVariantValidity(variant) {
        const index = _.findIndex(variantFieldsValidity, { id: variant.id });
        const variantsValidityClone = _.cloneDeep(variantFieldsValidity);
        if (index > -1) {
            variantsValidityClone[index] = variant;
            setVariantFieldsValidity(variantsValidityClone);
        } else {
            variantsValidityClone.push(variant);
            setVariantFieldsValidity(variantsValidityClone);
        }
    }

    return (
        <>
            <Stack>
                <Stack spacing={1}>
                    <BackButton />
                    <BrandProductEditButtons
                        isNewProduct={product?.id === ''}
                        isSubmitting={isSubmitting}
                        productChanged={true}
                        onSave={handleSubmit}
                    />
                </Stack>
                <Stack gap={2} direction={{ xs: 'column', sm: 'row' }}>
                    <Stack sx={{ width: '100%', maxWidth: { xs: 350, sm: 275 } }}>
                        <EditCardWrapper title={'Product'}>
                            <Box sx={{ display: 'flex', overflow: 'hidden' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', height: '50px', width: '100%' }}>
                                    <ProductIcon
                                        sx={{
                                            marginRight: '15px',
                                            flexGrow: '0',
                                            alignContent: 'center',
                                            justifyContent: 'left',
                                        }}
                                        src={product.imageLinks?.[0] || ''}
                                    />
                                    <Box sx={{ flexGrow: '1' }}>
                                        <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                            {product.title}
                                        </Typography>
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography
                                                align="center"
                                                sx={{
                                                    flexGrow: '1',
                                                    fontSize: '10px',
                                                    display: 'flex',
                                                    marginRight: '4px',
                                                    alignItems: 'center',
                                                }}>
                                                {product.productVariants.length} Variants
                                            </Typography>
                                            {product.active ? <Chip size="small" label="active" /> : ''}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </EditCardWrapper>
                        <EditCardWrapper
                            actionText={showText}
                            action={() => setHideInactive(!hideInactive)}
                            title={'Variants'}
                            sx={{ padding: 0, marginBottom: 0 }}>
                            <EditProductVariantSelect
                                selected={newProduct.productVariants[selectedVariantIndex]}
                                options={filteredVisibleVariants()}
                                onChange={handleNewVariantSelected}
                            />
                        </EditCardWrapper>
                    </Stack>
                    <ProductVariantEditPage
                        variantFieldsValidity={variantFieldsValidity}
                        onValidityChange={handleVariantValidity}
                        selectedVariant={newProduct.productVariants[selectedVariantIndex]}
                        onChange={handleVariantChange}
                    />
                </Stack>
            </Stack>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <MissingFieldsModal
                open={missingFieldsModalOpen}
                missingFields={checkRequiredFieldValuesVariants(newProduct, requiredFields)}
                onClose={() => setMissingFieldsModalOpen(false)}
            />
        </>
    );
}
