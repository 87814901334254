export const SettingsType = {
    UserProfile: 'UserProfile',
    Notifications: 'Notifications',
    Payment: 'Payment',
    CompanyProfile: 'CompanyProfile',
    CompanyUsers: 'CompanyUsers',
    Invite: 'Invite',
    ManageAddresses: 'ManageAddresses',
    Integrations: 'Integrations',
};
