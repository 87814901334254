import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { OrderDiscountTypes } from '../constants/OrderDiscountTypes';
import { OrderTermsTypes } from '../constants/OrderTermsTypes';

const LOCAL_STORAGE_CART_KEY = 'thatch-carts';

export function getStoredCartContents(key = LOCAL_STORAGE_CART_KEY) {
    const raw = localStorage.getItem(key);
    return JSON.parse(raw);
}

/**
 * @typedef OrderItem
 * @property {string} id
 * @property {string} productId
 * @property {number} quantity
 */

// Initial cart state
export const initialState = {
    address: null,
    approvalNote: '',
    business: null, // business.id field of business
    businessId: null, // business.businessId field of business
    /** @type {OrderItem[]} */
    items: [], // Array of cart items
    productRefs: [], // Array of product ref items
    retailBusinessId: null, // business.id field of business
    discountAmount: 0,
    discountType: OrderDiscountTypes.DOLLARS,
    shippingCost: 0,
    terms: OrderTermsTypes.PAY_UP_FRONT,
};

// Create context
export const CartContext = createContext();

/**
 * Reducer to manage cart state changes
 * @param {Object} state
 * @param {OrderItem[]} state.items
 * @param {*} action
 * @returns
 */
export function cartReducer(state, action) {
    switch (action.type) {
        case 'ADD_ITEM': {
            // Logic to add item to cart
            // Update if there is an existing
            const existing = state.items.findIndex((item) => item.id === action.payload.id);
            if (existing > -1) {
                const items = [...state.items.map((i) => ({ ...i }))];
                const { quantity } = items[existing];
                items[existing] = { ...items[existing], quantity: quantity + action.payload.quantity };
                return {
                    ...state,
                    items,
                };
            }
            return {
                ...state,
                business: action.payload.business,
                businessId: action.payload.businessId,
                items: [...state.items, action.payload],
            };
        }
        case 'ADD_PRODUCT_REF': {
            const existing = state.productRefs.findIndex((product) => product.id === action.payload.id);
            if (existing > -1) {
                return state;
            }
            return {
                ...state,
                productRefs: [...state.productRefs, action.payload],
            };
        }
        case 'UPDATE_ITEM': {
            // make a copy of items
            const items = [...state.items.map((i) => ({ ...i }))];
            const index = items.findIndex((item) => item.id === action.payload.id);
            if (items[index]) {
                items[index] = { ...items[index], ...action.payload };
            }
            return {
                ...state,
                items,
            };
        }
        case 'REPLACE': {
            return action.payload;
        }
        case 'REMOVE_ITEM': {
            // Logic to remove item from cart
            const found = state.items.find((item) => item.id === action.payload.id);
            const update = state.items.filter((item) => item.id !== action.payload.id);
            const hasOtherVariantsOfProduct = found && update.find((item) => item.productId === found.productId);

            let productRefsUpdate = state.productRefs;
            if (!hasOtherVariantsOfProduct) {
                productRefsUpdate = productRefsUpdate.filter((p) => p.id !== found.productId);
            }
            return {
                ...state,
                items: update,
                business: !update.length ? null : state.business,
                businessId: !update.length ? null : state.businessId,
                productRefs: !update.length ? [] : productRefsUpdate,
            };
        }
        case 'CLEAR_CART': {
            // Logic to remove business id and all items from cart
            if (action?.payload?.cartId) {
                localStorage.removeItem(action.payload.cartId);
            }
            return {
                ...state,
                ...initialState,
                address: state.address,
            };
        }
        case 'UPDATE_ADDRESS': {
            return {
                ...state,
                address: action.payload,
            };
        }
        case 'UPDATE_RETAIL_BUSINESS': {
            return {
                ...state,
                retailBusinessId: action.payload.businessId,
                retailBusiness: action.payload.id,
                retailBusinessObject: action.payload,
            };
        }
        case 'UPDATE_ORDER_DETAILS': {
            return {
                ...state,
                approvalNote: action.payload.approvalNote,
                discountAmount: action.payload.discountAmount,
                discountType: action.payload.discountType,
                shippingCost: action.payload.shippingCost,
                terms: action.payload.terms,
            };
        }

        default:
            return state;
    }
}

export const CartProvider = ({ children, storageKey = LOCAL_STORAGE_CART_KEY }) => {
    const [state, dispatch] = useReducer(cartReducer, initialState, (initial) => {
        const persisted = localStorage.getItem(storageKey);
        return persisted ? JSON.parse(persisted) : initial;
    });

    useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify(state));
    }, [state]);

    useEffect(() => {
        const persisted = localStorage.getItem(storageKey);
        const payload = persisted ? JSON.parse(persisted) : initialState;
        dispatch({ type: 'REPLACE', payload });
    }, [storageKey]);

    // Cart actions
    const addItem = (item) => {
        dispatch({ type: 'ADD_ITEM', payload: item });
    };

    const addProductRef = (item) => {
        dispatch({ type: 'ADD_PRODUCT_REF', payload: item });
    };

    const removeItem = (itemId) => {
        dispatch({ type: 'REMOVE_ITEM', payload: { id: itemId } });
    };

    const clearCart = (cartId) => {
        dispatch({ type: 'CLEAR_CART', payload: { cartId } });
    };

    const updateAddress = (payload) => {
        dispatch({ type: 'UPDATE_ADDRESS', payload });
    };

    const updateOrderDetails = (payload) => {
        dispatch({ type: 'UPDATE_ORDER_DETAILS', payload });
    };

    const updateItem = (payload) => {
        dispatch({ type: 'UPDATE_ITEM', payload });
    };

    const updateRetailBusiness = (payload) => {
        dispatch({ type: 'UPDATE_RETAIL_BUSINESS', payload });
    };

    // Provide state and actions to children
    return (
        <CartContext.Provider
            value={{
                state,
                addItem,
                addProductRef,
                removeItem,
                clearCart,
                updateAddress,
                updateOrderDetails,
                updateItem,
                updateRetailBusiness,
            }}>
            {children}
        </CartContext.Provider>
    );
};

CartProvider.propTypes = {
    children: PropTypes.node,
    storageKey: PropTypes.string,
};
