import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as api from '../../api';
import backgroundImage from '../../assets/SetupSuccessBG.png';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import * as businessProvider from '../../providers/userProvider.js';
import { useTranslation } from 'react-i18next';
import SignUpSuccessIcon from '../../icons/SignUpSuccessIcon.jsx';
import WholeshopLogo from '../../icons/WholeshopLogo.jsx';

export async function loader() {
    const business = await api.getDefaultBusiness();
    if (business && business.id) {
        api.confirmBusiness(business.id)
            .then(() => {
                return { business };
            })
            .catch((error) => {
                console.error('Failed to confirm business:', error);
            });
    }
    return {
        business,
    };
}

export default function BusinessSetupSuccess() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const navigateAway = () => {
        businessProvider.clearCache(); // Clear any previously cached business that might not have business type set causing problematic redirects
        navigate('/');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                height: '100vh',
                backgroundImage: `url(${backgroundImage})`,
                padding: 3,
            }}>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Stack
                    gap={1}
                    sx={{
                        width: {
                            xs: '90%',
                            sm: '500px',
                        },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <SignUpSuccessIcon sx={{ color: 'common.Midnight', width: 100, height: 100 }} />
                    <Typography variant="displayMd" sx={{ textAlign: 'center' }}>
                        {t('BusinessSetupSuccess.welcomeMessage')}
                    </Typography>
                    <Stack gap={3} sx={{ alignItems: 'center' }}>
                        <Typography sx={{ textAlign: 'center', color: 'common.Teal', width: '70%' }}>
                            {t('BusinessSetupSuccess.congratulationsMessage')}
                        </Typography>
                        <Typography sx={{ textAlign: 'center', color: 'common.Teal' }}>
                            {t('BusinessSetupSuccess.enjoyMessage')}
                        </Typography>
                        <Button variant="contained" onClick={navigateAway} sx={{ width: '100%' }}>
                            {t('BusinessSetupSuccess.getStartedButton')}
                        </Button>
                    </Stack>
                </Stack>
            </Box>
            <WholeshopLogo sx={{ height: '25.7px', width: '150px', mb: 5 }} />
        </Box>
    );
}
