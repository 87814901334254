import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {
    addTagValuesToRows,
    createAllVariantsFromOptions,
    filterInactiveAndSetDefault,
    getProductVariantColumns,
    mergeOldAndNewVariants,
    mergeTagNamesToColumns,
    variantsAllSame,
} from './productUtil.js';
import PropTypes from 'prop-types';
import { usePrevious } from '../../../../../helpers/customHooks.js';
import { Box, Button, ButtonGroup } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';

export function ProductVariantTable(props) {
    const [hideInactive, setHideInactive] = useState(true);
    const columns = getProductVariantColumns(handleDeleteClick);
    const productVariants = _.get(props, 'product.productVariants') || [];
    const productId = props?.product?.id;
    const tagOptions = _.get(props, 'product.productVariantTagOptions') || [];
    const rowsWithTagValue = addTagValuesToRows(productVariants);
    const columnsWithTagNames = mergeTagNamesToColumns(tagOptions, columns);
    const productWAllVariants = createAllVariantsFromOptions(props?.product || {});
    const prevProduct = usePrevious(_.cloneDeep(productWAllVariants));
    const navigate = useNavigate();

    useEffect(() => {
        const v = variantsAllSame(productWAllVariants, prevProduct, tagOptions);
        if (!v) {
            const newRows = mergeOldAndNewVariants(
                rowsWithTagValue,
                productWAllVariants.productVariants,
                productWAllVariants
            );
            props.onChange(newRows);
        }
    }, [props.product]);

    function handleRowEdit(row) {
        const found = _.findIndex(rowsWithTagValue, { id: row.id });
        const rowsCopy = _.cloneDeep(rowsWithTagValue);
        rowsCopy.splice(found, 1, row);
        props.onChange(rowsCopy);
        return row;
    }

    function handleDeleteClick(cell) {
        if (cell.field === 'active') {
            const found = _.findIndex(rowsWithTagValue, { id: cell.row.id });
            const rowsCopy = _.cloneDeep(rowsWithTagValue);
            cell.row.active = !cell.row.active;
            rowsCopy.splice(found, 1, cell.row);
            props.onChange(rowsCopy);
            return cell;
        }
        if (cell.field === 'isDefault') {
            const found = _.findIndex(rowsWithTagValue, { id: cell.row.id });
            const rowsCopy = _.cloneDeep(rowsWithTagValue);
            cell.row.isDefault = !cell.row.isDefault;
            rowsCopy.forEach((row) => {
                row.isDefault = false;
            });
            rowsCopy.splice(found, 1, cell.row);
            props.onChange(rowsCopy);
            return cell;
        }
    }

    function handleShow() {
        setHideInactive(!hideInactive);
    }

    const getClass = (params) => {
        if (!params.row.active) {
            return {
                disabledPvRow: true,
            };
        }
    };

    function navigateToEditPage() {
        navigate(`/brand/products/edit-variants/${productId}`);
    }

    const r = filterInactiveAndSetDefault(rowsWithTagValue, hideInactive);
    return (
        <>
            {r.length === 1 ? (
                ''
            ) : (
                <Paper sx={{ padding: 2, marginBottom: 2 }}>
                    <Box>
                        <ButtonGroup
                            sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 2 }}
                            aria-label="Basic button group">
                            {props?.product?.id ? (
                                <Button id="show-archive" component="span" onClick={handleShow}>
                                    {hideInactive ? 'Show Archived' : 'Hide Archived'}
                                </Button>
                            ) : (
                                ''
                            )}
                            {props?.product?.id && props?.product?.productVariants.length > 0 ? (
                                <Button id="show-archive" component="span" onClick={navigateToEditPage}>
                                    Edit Variant Details
                                </Button>
                            ) : (
                                ''
                            )}
                        </ButtonGroup>
                    </Box>
                    <DataGridPro
                        sx={{
                            '& .disabledPvRow': { color: 'common.mediumGrey' },
                        }}
                        key={productVariants?.id}
                        rows={r}
                        columns={columnsWithTagNames}
                        processRowUpdate={handleRowEdit}
                        onProcessRowUpdateError={console.error}
                        onCellClick={handleDeleteClick}
                        getRowClassName={(params) => {
                            return getClass(params);
                        }}
                        isRowSelectable={(params) => {
                            return params.row.active === false;
                        }}
                    />
                </Paper>
            )}
        </>
    );
}

ProductVariantTable.propTypes = {
    onChange: PropTypes.func.isRequired,
    product: PropTypes.object,
};
