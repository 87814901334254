import * as React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { Box, Button, Stack, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export default function QuantityInput(props) {
    const { hideButtons = false, sx } = props;
    const [error, setError] = React.useState();
    const theme = useTheme();

    const { defaultValue, onChange, value, min = 1, max = 9999 } = props;
    const increment = (e) => {
        let val = Number(value) + 1;
        if (val >= max) {
            val = max;
        }
        if (onChange) {
            onChange(e, val);
        }
    };
    const decrement = (e) => {
        let val = Number(value) - 1;
        if (val <= min) {
            val = min;
        }
        if (onChange) {
            onChange(e, val);
        }
    };

    const handleOnChange = (values, sourceInfo) => {
        if (values.floatValue !== undefined) {
            setError(false);
            if (onChange) {
                onChange(sourceInfo.event, values.floatValue);
            }
        } else {
            setError(true);
        }
    };

    const handleOnBlur = (e) => {
        if (e.target.value === '') {
            setError(true);
        } else {
            setError(false);
        }
    };

    const handleOnFocus = (e) => {
        if (props.onFocus) {
            props.onFocus(e);
        }
    };

    const inputDigits = `${value}`.length > 2 ? `${value}`.length : 2;
    return (
        <Box className="QuantityInput-root" sx={sx}>
            <Stack
                className={`QuantityInput-inputBorder${error ? ' error' : ''}`}
                sx={{
                    borderRadius: 2,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'common.Fog',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    '& input': {
                        outline: 'none',
                        paddingTop: '5px',
                        color: sx?.color || theme.palette.common.Tide,
                    },
                }}>
                {!hideButtons && (
                    <Button
                        sx={{
                            minWidth: 0,
                            pl: '10px',
                            pr: '3px',
                            border: 'none',
                            '&:hover': {
                                border: 'none',
                            },
                        }}
                        onClick={decrement}
                        size="micro"
                        variant="outlined">
                        <RemoveIcon sx={{ fontSize: '16px' }} />
                    </Button>
                )}
                <NumericFormat
                    defaultValue={defaultValue}
                    required
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                    allowNegative={false}
                    onValueChange={handleOnChange}
                    isAllowed={(values) => {
                        const { floatValue, value } = values;
                        return (
                            (floatValue >= min && floatValue <= max) || (min === 1 && floatValue !== 0) || value === ''
                        );
                    }}
                    style={{
                        textAlign: 'center',
                        border: 'none',
                        fontFamily: sx?.fontFamily || 'SalisburyBold',
                        lineHeight: sx?.lineHeight || '22px',
                    }}
                    size={inputDigits}
                    value={value}
                    valueIsNumericString
                />
                {!hideButtons && (
                    <Button
                        sx={{
                            minWidth: 0,
                            pr: '10px',
                            pl: '3px',
                            border: 'none',
                            '&:hover': {
                                border: 'none',
                            },
                        }}
                        onClick={increment}
                        size="micro"
                        variant="outlined">
                        <AddIcon sx={{ fontSize: '16px' }} />
                    </Button>
                )}
            </Stack>
        </Box>
    );
}

QuantityInput.propTypes = {
    defaultValue: PropTypes.number,
    hideButtons: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    sx: PropTypes.object,
    value: PropTypes.number,
};
