import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import backgroundImage from '../../assets/SignInBG.png';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

export default function SignInTextBG({ text }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                height: '100vh',
                backgroundImage: `url(${backgroundImage})`,
            }}>
            <Typography
                variant="displayLg"
                sx={{
                    width: '70%',
                    color: 'common.white',
                    whiteSpace: 'pre-line',
                    mb: 10,
                }}>
                <Trans i18nKey="SignUp.welcomeLabel" />
            </Typography>
        </Box>
    );
}

SignInTextBG.propTypes = {
    text: PropTypes.string,
};
