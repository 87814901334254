import { useTranslation } from 'react-i18next';
import { Form, useFetcher, useLoaderData } from 'react-router-dom';
import * as React from 'react';
import { useEffect, useState } from 'react';
import PhoneInput from '../../../components/PhoneInput/PhoneInput.jsx';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { CompanyProfileInputTypes } from '../../../constants/CompanyProfileInputTypes.jsx';
import CompanyProfileHeader from '../../../components/Profiles/CompanyProfileDetails/CompanyProfileHeader.jsx';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import _ from 'lodash';
import Card from '@mui/material/Card';
import FormInputLabel from '../../../components/FormInputLabel/FormInputLabel.jsx';
import StateSelect from '../../../components/StateSelect/StateSelect.jsx';
import LoadingButton from '../../../components/LoadingButton/LoadingButton.jsx';
import BuildingsIcon from '../../../icons/BuildingsIcon.jsx';
import { Alert, Teal } from '../../../theme.js';
import Crumbs from '../../../components/Crumbs/Crumbs.jsx';
import ManageUsers from '../../../components/ManageUsers/ManageUsers.jsx';
import CoverPhotoUploader from '../../../components/CoverPhotoUploader/CoverPhotoUploader.jsx';
import { BusinessTypes } from '../../../constants/BusinessTypes.jsx';
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput.jsx';
import CardContent from '@mui/material/CardContent';
import { matchIsValidTel } from 'mui-tel-input';
import * as api from '../../../api.js';
import * as businessProvider from '../../../providers/businessProvider.js';
import { useError } from '../../../components/Error/ErrorProvider.jsx';

export async function action({ request }) {
    const body = await request.json();
    const { intent, payload } = body;
    try {
        await api.updateBusiness(payload.id, payload, {});
        businessProvider.clearCache();
        return { success: true, intent };
    } catch (error) {
        return { success: false, intent, error };
    }
}

export default function CompanyProfile() {
    // Helpers
    const { t } = useTranslation();
    const fetcher = useFetcher();
    const { handleError } = useError();
    const data = useLoaderData();

    const [initialBusiness, setInitialBusiness] = useState(data.business || {});
    const [business, setBusiness] = useState(initialBusiness);
    const [charCount, setCharCount] = useState(business?.aboutUs?.length || 0);
    // Initial Data

    // Styling
    const responsiveDirection = { xs: 'column', sm: 'row' };
    const responsiveStyle = {
        sx: {
            alignItems: {
                xs: 'flex-start',
                sm: 'center',
            },
            justifyContent: {
                xs: 'flex-start',
                sm: 'center',
            },
            width: '100%',
        },
    };

    // State Objects
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalTitle, setModalTitle] = useState('');

    // Errors
    const [requiredFieldErrors, setRequiredFieldErrors] = useState({
        businessName: false,
        established: false,
        street1: false,
        city: false,
        state: false,
        postalCode: false,
        phone: false,
        secondaryPhone: false,
    });

    // Helper Functions
    const hasBusinessChanged = () => {
        return JSON.stringify(initialBusiness) !== JSON.stringify(business);
    };
    const handleCancelEdit = () => {
        setBusiness(initialBusiness);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const isFormValid = () => {
        for (const key in requiredFieldErrors) {
            if (requiredFieldErrors[key] === true) {
                return false;
            }
        }
        return true;
    };
    const showModal = (title, message) => {
        setModalTitle(title);
        setModalMessage(message);
        setOpenModal(true);
    };
    const validateForm = () => {
        const invalidBusinessName = business?.name === '';
        const invalidStreet = _.isEmpty(business.addresses[0].street1);
        const invalidCity = _.isEmpty(business.addresses[0].city);
        const invalidPostalCode = _.isEmpty(business.addresses[0].postalCode);
        const phone = business.addresses[0].phones[0].primary ?? '';
        const secondary = business.addresses[0].phones[0].secondary;
        const invalidPhoneNumber = !_.isEmpty(phone) && !matchIsValidTel(phone);
        const invalidSecondary = !_.isEmpty(secondary) && !matchIsValidTel(secondary);
        const invalidEstablished = business?.established === '' ? false : business.established?.length !== 4;

        setRequiredFieldErrors((prevState) => ({
            ...prevState,
            businessName: invalidBusinessName,
            established: invalidEstablished,
            street1: invalidStreet,
            city: invalidCity,
            phone: invalidPhoneNumber,
            secondaryPhone: invalidSecondary,
            postalCode: invalidPostalCode,
        }));
    };
    function handleSaveEdit() {
        if (isFormValid()) {
            setIsLoading(true);
            fetcher.submit(
                {
                    intent: 'update',
                    payload: business,
                },
                { method: 'post', encType: 'application/json' }
            );

            // showModal('Success', 'Your info has been updated.');
        } else {
            showModal('Missing Info', 'Please fill out all fields correctly.');
        }
    }

    const handleCoverPhotoChange = (imageUrl) => {
        setBusiness({
            ...business,
            coverPhoto: imageUrl,
        });
    };

    const handleProfileImageChange = (imageUrl) => {
        fetcher.submit(
            {
                intent: 'profileImage',
                payload: { ...business, imageLinks: [imageUrl] },
            },
            { method: 'post', encType: 'application/json' }
        );
    };
    const handleTextFieldChange = (event, textField) => {
        switch (textField) {
            case CompanyProfileInputTypes.BUSINESSNAME:
                setBusiness({
                    ...business,
                    name: event.target.value,
                });
                break;
            case CompanyProfileInputTypes.BUSINESSTYPE:
                setBusiness({
                    ...business,
                    type: event.target.value,
                });
                break;
            case CompanyProfileInputTypes.ESTABLISHED:
                setBusiness({
                    ...business,
                    established: event.target.value,
                });
                break;
            case CompanyProfileInputTypes.STREET1:
                setBusiness({
                    ...business,
                    addresses: business.addresses.map((address, index) =>
                        index === 0 ? { ...address, street1: event.target.value } : address
                    ),
                });
                break;
            case CompanyProfileInputTypes.CITY:
                setBusiness({
                    ...business,
                    addresses: business.addresses.map((address, index) =>
                        index === 0 ? { ...address, city: event.target.value } : address
                    ),
                });
                break;
            case CompanyProfileInputTypes.ZIP:
                setBusiness({
                    ...business,
                    addresses: business.addresses.map((address, index) =>
                        index === 0 ? { ...address, postalCode: event.target.value } : address
                    ),
                });
                break;
            case CompanyProfileInputTypes.STATE:
                setBusiness({
                    ...business,
                    addresses: business.addresses.map((address, index) =>
                        index === 0 ? { ...address, state: event.target.value } : address
                    ),
                });
                break;
            case CompanyProfileInputTypes.ABOUTUS:
                if (event.target.value.length <= 1000) {
                    setCharCount(event.target.value.length);
                    setBusiness({
                        ...business,
                        aboutUs: event.target.value,
                    });
                }
                break;
            case CompanyProfileInputTypes.PHONE:
                setBusiness({
                    ...business,
                    addresses: business.addresses.map((address, index) =>
                        index === 0
                            ? {
                                  ...address,
                                  phones: address.phones.map((phone, phoneIndex) =>
                                      phoneIndex === 0
                                          ? {
                                                ...phone,
                                                primary: _.trim(event),
                                            }
                                          : phone
                                  ),
                              }
                            : address
                    ),
                });
                break;
            case CompanyProfileInputTypes.SECONDARYPHONE:
                setBusiness({
                    ...business,
                    addresses: business.addresses.map((address, index) =>
                        index === 0
                            ? {
                                  ...address,
                                  phones: address.phones.map((phone, phoneIndex) =>
                                      phoneIndex === 0
                                          ? {
                                                ...phone,
                                                secondary: _.trim(event),
                                            }
                                          : phone
                                  ),
                              }
                            : address
                    ),
                });
                break;
            case CompanyProfileInputTypes.MINORDER:
                setBusiness({
                    ...business,
                    minOrderValue: event.target.value,
                });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        validateForm();
    }, [business]);

    useEffect(() => {
        if (fetcher.state === 'idle') {
            setIsLoading(false);

            if (fetcher.data) {
                const { intent, success, error } = fetcher.data;
                if (success) {
                    if (intent === 'update') {
                        setInitialBusiness(business);
                        showModal('Success', 'Your business info has been updated.');
                    }
                } else if (error) {
                    handleError(error);
                } else {
                    handleError('Something went wrong. Please try again.');
                }
            }
        }
    }, [fetcher.state, fetcher.data]);

    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                width: '100%',
            }}>
            <Stack spacing={3} justifyContent="center" sx={{ maxWidth: '600px', minWidth: '250px' }}>
                <Box sx={{ pb: 1 }}>
                    <Crumbs />
                </Box>
                <Stack gap={1} direction="row" sx={{ alignItems: 'center' }}>
                    <BuildingsIcon sx={{ width: 32, height: 32, color: Teal }} />
                    <Typography variant="displaySm">Company Info</Typography>
                </Stack>
                <CompanyProfileHeader
                    isEditable={true}
                    onCancelEdit={handleCancelEdit}
                    onSaveEdit={handleSaveEdit}
                    onImageChange={handleProfileImageChange}>
                    {business.type === BusinessTypes.BRAND ? (
                        <FormInputLabel text={t('CompanyProfile.minOrderInputLabel')}>
                            <CurrencyInput
                                fullWidth
                                value={business.minOrderValue}
                                placeholder={t('CompanyProfile.minOrderInputPlaceholder')}
                                name="minOrderValue"
                                disableDecimals
                                sx={{ maxWidth: 200 }}
                                onChange={(event) => handleTextFieldChange(event, CompanyProfileInputTypes.MINORDER)}
                            />
                        </FormInputLabel>
                    ) : null}
                    {business.type === BusinessTypes.BRAND ? (
                        <FormInputLabel text={t('CompanyProfile.coverPhotoInputLabel')}>
                            <CoverPhotoUploader
                                coverPhoto={business.coverPhoto}
                                onImageChange={handleCoverPhotoChange}
                            />
                        </FormInputLabel>
                    ) : null}
                </CompanyProfileHeader>
                <Card>
                    <CardContent sx={{ padding: '24px' }}>
                        <Stack gap={3} sx={{ display: 'flex', width: '100%' }}>
                            <Typography variant="textLg" sx={{ fontWeight: 'bold' }}>
                                Company Details
                            </Typography>
                            <Form>
                                <Stack gap={2}>
                                    <Stack gap={2} direction={responsiveDirection} sx={{ ...responsiveStyle }}>
                                        <Box sx={{ flex: 1 }}>
                                            <FormInputLabel text={t('CompanyProfile.businessNameInputLabel')} required>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    name="businessName"
                                                    value={business.name}
                                                    error={requiredFieldErrors.businessName}
                                                    onChange={(event) =>
                                                        handleTextFieldChange(
                                                            event,
                                                            CompanyProfileInputTypes.BUSINESSNAME
                                                        )
                                                    }
                                                />
                                            </FormInputLabel>
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <FormInputLabel text={t('CompanyProfile.businessTypeInputLabel')}>
                                                <TextField
                                                    fullWidth
                                                    disabled={true}
                                                    name="businessType"
                                                    value={business.type}
                                                    onChange={(event) =>
                                                        handleTextFieldChange(
                                                            event,
                                                            CompanyProfileInputTypes.BUSINESSTYPE
                                                        )
                                                    }
                                                />
                                            </FormInputLabel>
                                        </Box>
                                    </Stack>
                                    <FormInputLabel text={t('CompanyProfile.establishedInputLabel')}>
                                        <TextField
                                            sx={{ width: '50%' }}
                                            name="established"
                                            placeholder="Enter Year"
                                            value={business.established}
                                            error={requiredFieldErrors.established}
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                if (value.length <= 4 && /^\d*$/.test(value)) {
                                                    handleTextFieldChange(event, CompanyProfileInputTypes.ESTABLISHED);
                                                }
                                            }}
                                            inputProps={{
                                                maxLength: 4,
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*',
                                            }}
                                        />
                                    </FormInputLabel>
                                    <Stack>
                                        <FormInputLabel text={t('CompanyProfile.bioInputLabel')}>
                                            <TextField
                                                multiline
                                                rows={5}
                                                fullWidth
                                                name="aboutUs"
                                                value={business.aboutUs}
                                                placeholder={t('CompanyProfile.bioPlaceholder')}
                                                onChange={(event) =>
                                                    handleTextFieldChange(event, CompanyProfileInputTypes.ABOUTUS)
                                                }
                                            />
                                        </FormInputLabel>
                                        {charCount >= 1000 && (
                                            <Typography variant="textSm" sx={{ color: Alert }}>
                                                {t('CompanyProfile.characterLimitExceeded')}
                                            </Typography>
                                        )}
                                    </Stack>
                                    <Stack gap={2} direction={responsiveDirection} sx={{ ...responsiveStyle }}>
                                        <Box sx={{ flex: 1 }}>
                                            <FormInputLabel text={t('CompanyProfile.addressInputLabel')} required>
                                                <TextField
                                                    fullWidth
                                                    name="address"
                                                    value={business.addresses[0].street1}
                                                    error={requiredFieldErrors.street1}
                                                    onChange={(event) =>
                                                        handleTextFieldChange(event, CompanyProfileInputTypes.STREET1)
                                                    }
                                                />
                                            </FormInputLabel>
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <FormInputLabel text={t('CompanyProfile.cityInputLabel')} required>
                                                <TextField
                                                    fullWidth
                                                    name="city"
                                                    value={business.addresses[0].city}
                                                    error={requiredFieldErrors.city}
                                                    onChange={(event) =>
                                                        handleTextFieldChange(event, CompanyProfileInputTypes.CITY)
                                                    }
                                                />
                                            </FormInputLabel>
                                        </Box>
                                    </Stack>
                                    <Stack gap={2} direction={responsiveDirection} sx={{ ...responsiveStyle }}>
                                        <Box sx={{ width: '40%' }}>
                                            <FormInputLabel text={t('CompanyProfile.stateInputLabel')}>
                                                <StateSelect
                                                    name="state"
                                                    defaultValue={business.addresses[0].state}
                                                    onChange={(event) =>
                                                        handleTextFieldChange(event, CompanyProfileInputTypes.STATE)
                                                    }
                                                />
                                            </FormInputLabel>
                                        </Box>
                                        <FormInputLabel text={t('CompanyProfile.zipInputLabel')} required>
                                            <TextField
                                                fullWidth
                                                name="postalCode"
                                                value={business.addresses[0].postalCode}
                                                error={requiredFieldErrors.postalCode}
                                                onChange={(event) =>
                                                    handleTextFieldChange(event, CompanyProfileInputTypes.ZIP)
                                                }
                                            />
                                        </FormInputLabel>
                                    </Stack>
                                    <Stack gap={2} direction={responsiveDirection} sx={{ ...responsiveStyle }}>
                                        <Box sx={{ flex: 1 }}>
                                            <FormInputLabel text={t('CompanyProfile.phoneInputLabel')}>
                                                <PhoneInput
                                                    required
                                                    fullWidth
                                                    name="phone"
                                                    value={business.addresses[0]?.phones[0]?.primary ?? ''}
                                                    error={requiredFieldErrors.phone}
                                                    onChange={(event) =>
                                                        handleTextFieldChange(event, CompanyProfileInputTypes.PHONE)
                                                    }
                                                />
                                            </FormInputLabel>
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <FormInputLabel text={t('CompanyProfile.secondaryPhoneInputLabel')}>
                                                <PhoneInput
                                                    fullWidth
                                                    name="secondaryPhone"
                                                    value={business.addresses[0]?.phones[0]?.secondary ?? ''}
                                                    error={requiredFieldErrors.secondaryPhone}
                                                    onChange={(event) =>
                                                        handleTextFieldChange(
                                                            event,
                                                            CompanyProfileInputTypes.SECONDARYPHONE
                                                        )
                                                    }
                                                />
                                            </FormInputLabel>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Form>
                            <LoadingButton
                                onClick={handleSaveEdit}
                                disabled={!hasBusinessChanged()}
                                isLoading={isLoading}
                                color="secondary"
                                variant="outlined">
                                Save Changes
                            </LoadingButton>
                        </Stack>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent sx={{ padding: '24px' }}>
                        <ManageUsers businessId={business.businessId} />
                    </CardContent>
                </Card>
            </Stack>
            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>{modalTitle}</DialogTitle>
                <DialogContent>
                    <Typography>{modalMessage}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary" variant="contained">
                        {t('CompanyProfile.okButton')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
