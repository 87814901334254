import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Chip } from '@mui/material';
import Box from '@mui/material/Box';
import _ from 'lodash';
import { OrderStatuses } from '../../../constants/OrderStatuses.jsx';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { OrderItems } from './orderItems/OrderItems.jsx';
import OrderCardHeader from './OrderCardHeader.jsx';
import { Tide } from '../../../theme.js';

export default function RetailOrderListItem(props) {
    const { t } = useTranslation();
    const { business, order } = props;

    let statusColor;
    let statusIcon;

    if (order?.status === OrderStatuses.IN_REVIEW) {
        statusIcon = <RemoveRedEyeOutlinedIcon fontSize={'small'} />;
        statusColor = 'review';
    } else if (order?.status === OrderStatuses.APPROVED) {
        statusIcon = <ThumbUpOffAltIcon fontSize={'small'} />;
        statusColor = 'approved';
    } else if (order?.status === OrderStatuses.CANCELLED) {
        statusIcon = <WarningAmberIcon fontSize={'small'} />;
        statusColor = 'cancelled';
    } else if (order?.status === OrderStatuses.DECLINED) {
        statusIcon = <WarningAmberIcon fontSize={'small'} />;
        statusColor = 'cancelled';
    }

    return (
        <Card key={order?.id}>
            <OrderCardHeader order={order} business={business} />
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box></Box>
                    <Box></Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                    <Box>
                        <Chip
                            sx={{ fontWeight: '700', fontSize: '10px', padding: '8px 12px' }}
                            icon={statusIcon}
                            label={_.toUpper(order?.status)}
                            color={statusColor}
                        />
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-end' }}>
                            <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>{/* Order Items */}</Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                            sx={{ color: Tide, borderColor: Tide }}
                            fullWidth
                            variant="outlined"
                            size="small"
                            href={`/retail/orders/${order.id}`}>
                            {t('RetailOrderListItem.viewDetailsLabel')}
                        </Button>
                    </Box>
                </Box>
                <OrderItems order={order} />
            </CardContent>
        </Card>
    );
}

RetailOrderListItem.propTypes = {
    business: PropTypes.any,
    order: PropTypes.any,
};
