import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import ProductIcon from '../../../components/ProductIcon';
import { Teal, Tide, White } from '../../../theme';
import { sortTags } from './orderUtil';

export default function OrderProductSummary(props) {
    const { productTitle, quantity, items = [] } = props;
    const { t } = useTranslation();

    return (
        <Box>
            <Stack direction="row" alignItems="flex-end" gap={1} sx={{ mb: 2 }}>
                <Typography sx={{ fontWeight: 700, lineHeight: 'normal' }} variant="textXl">
                    {productTitle}
                </Typography>
                <Typography sx={{ fontWeight: 700, color: Teal, lineHeight: '26px' }} variant="textSm">
                    ({quantity})
                </Typography>
            </Stack>
            {items.map((item) => (
                <Stack
                    direction="row"
                    gap={2}
                    key={item.id}
                    sx={{
                        alignItems: {
                            xs: 'flex-start',
                            sm: 'center',
                        },
                        mb: 2,
                    }}>
                    <Box
                        sx={{
                            width: { xs: 32, sm: 48 },
                            height: { xs: 32, sm: 48 },
                            borderRadius: 1,
                            bgcolor: White,
                            boxShadow: '1px 1px 10px 0px rgba(0, 0, 0, 0.08)',
                            overflow: 'hidden',
                            flexShrink: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <ProductIcon
                            src={item?.imageLinks?.[0] || item.productVariant?.product?.imageLinks?.[0]}
                            CardMediaProps={{
                                sx: { objectFit: 'cover', aspectRatio: '1 / 1' },
                            }}
                        />
                    </Box>
                    <Stack sx={{ flexGrow: 1 }}>
                        {!item?.productVariant?.variantTags ? null : (
                            <Typography variant="textSm" sx={{ fontWeight: 500 }}>
                                {sortTags(item?.productVariant)
                                    .map((tag) => tag?.value)
                                    .join(' | ')}
                            </Typography>
                        )}
                        <Stack direction="row" gap="0 16px" flexWrap="wrap">
                            {!item?.productVariant?.sku ? null : (
                                <Typography sx={{ color: Teal }} variant="textXs">
                                    {t('RetailOrderDetails.skuLabel')}: {item?.productVariant?.sku}
                                </Typography>
                            )}
                            {!item?.productVariant?.mpn ? null : (
                                <Typography sx={{ color: Teal }} variant="textXs">
                                    {t('RetailOrderDetails.mpnLabel')}: {item?.productVariant?.mpn}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                    <Stack alignItems="center" direction="row" gap="0px 16px" flexWrap="wrap" justifyContent="flex-end">
                        <Typography sx={{ fontWeight: 600, color: Tide }}>
                            <NumericFormat
                                prefix="$"
                                displayType="text"
                                value={item.price}
                                thousandSeparator
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </Typography>
                        <Typography variant="label" sx={{ fontSize: 12 }}>
                            {t('RetailOrderDetails.quantityLabel')} {item.quantity}
                        </Typography>
                    </Stack>
                </Stack>
            ))}
        </Box>
    );
}

OrderProductSummary.propTypes = {
    productTitle: PropTypes.string,
    quantity: PropTypes.number,
    items: PropTypes.array,
};
