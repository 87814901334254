import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';

export default function EditCardWrapper({
    action,
    actionText = '',
    title,
    children,
    sx = { padding: 2, marginBottom: 2 },
}) {
    return (
        <Stack>
            <Box sx={{ display: 'flex', marginBottom: '10px' }}>
                <Typography variant="h7" gutterBottom sx={{ fontWeight: 'bold' }}>
                    {title}
                </Typography>

                {action ? (
                    <Button
                        size={'small'}
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        id="show-archive"
                        component="span"
                        onClick={action}>
                        {actionText}
                    </Button>
                ) : (
                    ''
                )}
            </Box>
            <Paper sx={sx}>{children}</Paper>
        </Stack>
    );
}

EditCardWrapper.propTypes = {
    action: PropTypes.func,
    actionText: PropTypes.string,
    title: PropTypes.string,
    sx: PropTypes.object,
    children: PropTypes.node,
};
