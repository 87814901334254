import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import PackageIcon from '../../icons/PackageIcon.jsx';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Fog, Midnight, Teal } from '../../theme.js';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { NumericFormat } from 'react-number-format';
import Divider from '@mui/material/Divider';
import OrderProductSummary from '../../routes/retail/orders/OrderProductSummary.jsx';
import {
    getChipColor,
    groupOrderItemsByProduct,
    groupProductsByProductId,
    selectItemsByProductId,
    selectProductById,
    selectTotalQuantityOfProductId,
} from '../../routes/retail/orders/orderUtil.js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function DealerLastOrder({ order }) {
    const productMap = groupOrderItemsByProduct(order);
    const productsByProductId = groupProductsByProductId(order);
    const productIds = Object.keys(productMap) || [];
    const navigate = useNavigate();
    const { t } = useTranslation();
    const formatOrderDate = (createdAt) => {
        const date = new Date(createdAt);
        const formattedDate = date.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        });
        const formattedTime = date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
        return `Order placed on ${formattedDate} @ ${formattedTime}`;
    };

    const navigateToAllOrders = () => {
        navigate(`/brand/orders?businessId=${order.retailBusinessId}`);
    };

    const navigateToCreateOrder = () => {
        navigate('/brand/new-order');
    };

    const navigateToOrder = () => {
        navigate(`/brand/orders/${order.id}`);
    };

    return (
        <Card>
            <CardContent sx={{ padding: '24px' }}>
                <Stack gap={2}>
                    <Stack gap={1} direction="row">
                        <PackageIcon sx={{ width: 24, height: 24 }} />
                        <Typography variant="textLg" sx={{ fontWeight: 700 }}>
                            {t('Dealers.lastOrderLabel')}
                        </Typography>
                    </Stack>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '8px',
                            border: `1px solid ${Fog}`,
                            paddingX: '24px',
                            paddingY: '16px',
                        }}>
                        <Stack
                            direction="row"
                            sx={{ width: '100%', alignItems: 'flex-top', justifyContent: 'space-between' }}>
                            <Stack gap={1}>
                                <Stack direction="row" sx={{ alignItems: 'center' }}>
                                    <Button
                                        onClick={navigateToOrder}
                                        sx={{ color: Teal, textDecoration: 'underline', padding: 0 }}>
                                        #{order?.orderNumber > 0 ? order?.orderNumber : order?.id}
                                    </Button>
                                    <Typography variant="textSm" sx={{ color: Midnight }}>
                                        {formatOrderDate(order.createdAt)}
                                    </Typography>
                                </Stack>
                                <Chip
                                    variant="orderStatus"
                                    color={getChipColor(order.status)}
                                    label={order.status}
                                    sx={{ width: 140 }}
                                />
                            </Stack>
                            <Typography variant="textLg" sx={{ fontWeight: 700 }}>
                                <NumericFormat
                                    value={order.total}
                                    displayType="text"
                                    thousandSeparator
                                    decimalScale={2}
                                    fixedDecimalScale={2}
                                    valueIsNumericString
                                    prefix={'$'}
                                />
                            </Typography>
                        </Stack>
                    </Box>
                    <Stack>
                        {productIds?.map((id, index) => (
                            <Stack key={id}>
                                {index !== 0 ? <Divider sx={{ my: '30px' }} /> : null}
                                <OrderProductSummary
                                    productTitle={selectProductById(productsByProductId, id)?.title}
                                    quantity={selectTotalQuantityOfProductId(productMap, id)}
                                    items={selectItemsByProductId(productMap, id)}
                                />
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
                <Stack gap={1} direction={{ xs: 'column', sm: 'row' }} sx={{ alignItems: 'center' }}>
                    <Button
                        onClick={navigateToAllOrders}
                        variant="outlined"
                        color="secondary"
                        sx={{ height: 40, fontSize: 14, width: { xs: '100%', sm: 'auto' } }}>
                        {t('Dealers.viewAllOrdersButton')}
                    </Button>
                    <Button
                        onClick={navigateToCreateOrder}
                        variant="contained"
                        sx={{ height: 40, fontSize: 14, width: { xs: '100%', sm: 'auto' } }}>
                        {t('Dealers.createNewOrderButton')}
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    );
}

DealerLastOrder.propTypes = {
    order: PropTypes.object,
};
