import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import * as api from '../../../../api.js';
import { useLoaderData } from 'react-router-dom';
import { useError } from '../../../../components/Error/ErrorProvider.jsx';
import CategorySelect from '../../../../components/CategorySelect/CategorySelect.jsx';
import { isEmpty } from '../../../../helpers/HelperFunctions.jsx';
import { usePrevious } from '../../../../helpers/customHooks.js';
import _ from 'lodash';
import Promise from 'bluebird';

export default function Categories({ categories, handleInputChange, fieldsValidity, handleValidity }) {
    const { handleError } = useError();
    const cats = useLoaderData().allCategory;

    const [selectedCategories, setSelectedCategories] = useState({
        main: null,
        sub1: null,
        sub2: null,
        sub3: null,
        sub4: null,
    });

    const [categoryOptions, setCategoryOptions] = useState({
        main: null,
        sub1: null,
        sub2: null,
        sub3: null,
        sub4: null,
    });

    const [isLoading, setIsLoading] = useState({
        main: false,
        sub1: false,
        sub2: false,
        sub3: false,
        sub4: false,
    });

    const loadInitialCategories = async (categoriesToFetch) => {
        const categoryMap = {
            0: 'main',
            1: 'sub1',
            2: 'sub2',
            3: 'sub3',
            4: 'sub4',
        };

        try {
            const categoriesToSelect = await Promise.reduce(
                categoriesToFetch,
                async (acc, categoryId, i) => {
                    if (categoryId) {
                        acc[categoryMap[i]] = await fetchCategory(categoryId);
                    }
                    return acc;
                },
                {}
            );
            setSelectedCategories(categoriesToSelect);
        } catch (error) {
            handleError('Error fetching categories:', error);
        }
    };

    const fetchCategory = async (categoryId, type) => {
        setIsLoading({
            ...isLoading,
            [type]: true,
        });

        try {
            return await api.getCategories(encodeURIComponent(categoryId));
        } catch (error) {
            handleError('Error fetching data:', error);
        } finally {
            setIsLoading({
                ...isLoading,
                [type]: false,
            });
        }
    };

    useEffect(() => {
        handleInputChange([
            selectedCategories.main?.id || null,
            selectedCategories.sub1?.id || null,
            selectedCategories.sub2?.id || null,
            selectedCategories.sub3?.id || null,
            selectedCategories.sub4?.id || null,
        ]);
    }, [selectedCategories]);

    const prevCategories = usePrevious(categories);
    useEffect(() => {
        if (!_.isEqual(prevCategories, categories)) {
            if (!_.isEmpty(categories)) {
                loadInitialCategories(categories).catch(console.error);
            }
        }
    }, [categories]);

    useEffect(() => {
        if (!_.isEmpty(selectedCategories)) {
            handleInputChange([]);
        }
    }, [selectedCategories]);

    useEffect(() => {
        if (!_.isEmpty(selectedCategories.sub1)) {
            //
            fetchCategory(selectedCategories.sub1.id)
                .then((result) => {
                    setCategoryOptions({
                        ...categoryOptions,
                        sub2: result,
                    });
                    return null;
                })
                .catch(console.error);
        }
    }, [selectedCategories.sub1]);

    useEffect(() => {
        if (!_.isEmpty(selectedCategories.sub2)) {
            //
            fetchCategory(selectedCategories.sub2.id)
                .then((result) => {
                    setCategoryOptions({
                        ...categoryOptions,
                        sub3: result,
                    });
                    return null;
                })
                .catch(console.error);
        }
    }, [selectedCategories.sub2]);

    useEffect(() => {
        if (!_.isEmpty(selectedCategories.sub3)) {
            //
            fetchCategory(selectedCategories.sub3.id)
                .then((result) => {
                    setCategoryOptions({
                        ...categoryOptions,
                        sub4: result,
                    });
                    return null;
                })
                .catch(console.error);
        }
    }, [selectedCategories.sub3]);

    const handleCategoryChange = (event) => {
        const categoryId = event.target.value;
        const categoryName = event.target.name;
        let categorySelection = {};

        switch (categoryName) {
            case 'main':
                categorySelection = {
                    sub1: null,
                    sub2: null,
                    sub3: null,
                };
                break;
            case 'sub1':
                categorySelection = {
                    sub2: null,
                    sub3: null,
                };
                break;
            case 'sub2':
                categorySelection = {
                    sub3: null,
                };
                break;
            case 'sub3':
                categorySelection = {};
                break;
        }

        setSelectedCategories({
            ...selectedCategories,
            ...categorySelection,
            [categoryName]: categoryId,
        });
    };

    function handleCategoryBlur(name) {
        return (e) => {
            const value = e.target.value;
            handleValidity({ target: { name, value } });
        };
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <CategorySelect
                    error={fieldsValidity?.categories}
                    onBlur={handleCategoryBlur('categories')}
                    label="Main Category"
                    name="main"
                    category={selectedCategories.main}
                    subcategories={cats?.subCategories}
                    disabled={false}
                    onChange={handleCategoryChange}
                    isLoading={isLoading.main}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <CategorySelect
                    label="Subcategory 1"
                    name="sub1"
                    category={selectedCategories.sub1}
                    subcategories={selectedCategories.main?.subCategories}
                    disabled={isEmpty(selectedCategories.main)}
                    onChange={handleCategoryChange}
                    isLoading={isLoading.sub1}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <CategorySelect
                    label="Subcategory 2"
                    name="sub2"
                    category={selectedCategories.sub2}
                    subcategories={categoryOptions.sub2?.subCategories}
                    disabled={isEmpty(selectedCategories.sub1)}
                    onChange={handleCategoryChange}
                    isLoading={isLoading.sub2}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <CategorySelect
                    label="Subcategory 3"
                    name="sub3"
                    category={selectedCategories.sub3}
                    subcategories={categoryOptions.sub3?.subCategories}
                    disabled={isEmpty(selectedCategories.sub2)}
                    onChange={handleCategoryChange}
                    isLoading={isLoading.sub3}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <CategorySelect
                    label="Subcategory 4"
                    name="sub4"
                    category={selectedCategories.sub4}
                    subcategories={categoryOptions.sub4?.subCategories}
                    disabled={isEmpty(selectedCategories.sub3)}
                    onChange={handleCategoryChange}
                    isLoading={isLoading.sub4}
                />
            </Grid>
        </Grid>
    );
}

Categories.propTypes = {
    categories: PropTypes.array,
    handleInputChange: PropTypes.func.isRequired,
    fieldsValidity: PropTypes.object.isRequired,
    handleValidity: PropTypes.func.isRequired,
};
