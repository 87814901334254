import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function CopyableTooltip({ children, text }) {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [showSuccess, setShowSuccess] = useState(false);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    // Function to show the snackbar
    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };
    const copyToClipboard = () => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                handleCopySuccess();
                return text;
            })
            .catch((error) => {
                showSnackbar('Error copying text: ' + error.message, 'error');
            });
    };

    const handleCopySuccess = () => {
        setShowSuccess(true);
    };

    const handleTooltipClose = () => {
        const timeout = setTimeout(() => {
            setShowSuccess(false);
        }, 300);
        return () => clearTimeout(timeout);
    };

    return (
        <>
            <Tooltip
                title={
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ height: '100%', width: '100%' }} // Adjust height and width as needed
                    >
                        {showSuccess ? (
                            <Typography sx={{ color: 'white', fontSize: '11px' }}>Copied!</Typography>
                        ) : (
                            <>
                                <Typography sx={{ color: 'white', fontSize: '11px' }}>{text}</Typography>
                                <Button
                                    onClick={copyToClipboard}
                                    sx={{
                                        height: '20px',
                                        color: 'white',
                                        fontSize: '10px',
                                        textTransform: 'none',
                                    }}>
                                    Copy
                                </Button>
                            </>
                        )}
                    </Box>
                }
                arrow
                placement="top"
                onClose={handleTooltipClose}>
                {children || <></>}
            </Tooltip>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

CopyableTooltip.propTypes = {
    text: PropTypes.string,
    children: PropTypes.node,
};
