import React from 'react';
import StickyHeader from '../../components/StickyHeader/StickyHeader.jsx';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import backgroundImage from '../../assets/LandingPageBG.png';
import image1 from '../../assets/LandingPage-1.png';
import image2 from '../../assets/landing-page-2.png';
import image3 from '../../assets/landing-page-3.png';
import screen1 from '../../assets/landing-screen--1.png';
import { Midnight, SoftBG, Straw, Tide, White } from '../../theme.js';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import LandingIconCard from '../../components/LandingIconCard/LandingIconCard.jsx';
import PackageIcon from '../../icons/PackageIcon.jsx';
import ChatIcon from '../../icons/ChatIcon.jsx';
import CreditCardIcon from '../../icons/CreditCardIcon.jsx';
import MicroChipIcon from '../../icons/MicroChipIcon.jsx';
import LandingFooter from '../../components/LandingFooter/LandingFooter.jsx';
import Grid from '@mui/material/Grid';
import { Trans } from 'react-i18next';
import GoogleAnalytics from '../../components/GoogleAnalytics/GoogleAnalytics.jsx';
import HubSpotAnalytic from '../../components/HubSpotAnalytic/HubSpotAnalytic.jsx';
import ThatchHeader from '../../components/StickyHeader/ThatchHeader.jsx';

export default function LandingPage() {
    const responsiveDirection = { xs: 'column', sm: 'row' };
    const imageSize = { width: { xs: 360, sm: 400, md: 450 }, height: { xs: 360, sm: 400, md: 450 } };
    const demoLink = 'https://hs.whole.shop/demo-sign-up';

    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', overflow: 'auto' }}>
            <GoogleAnalytics trackingId={'G-TJVBNR01V6'} />
            <HubSpotAnalytic />
            <ThatchHeader />
            <StickyHeader />
            <Stack
                sx={{
                    width: '100%',
                    height: '100%',
                    flexGrow: 1,
                    overflow: 'auto',
                }}>
                {/* Section 1 */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: { xs: 'flex-end', sm: 'center' },
                        width: '100%',
                        height: { xs: 1200, sm: 750 },
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        paddingTop: 5,
                    }}>
                    <Stack
                        direction={responsiveDirection}
                        sx={{
                            gap: { xs: 4, sm: 0 },
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mx: 10,
                            width: '100%',
                        }}>
                        <Stack gap={1} sx={{ mr: { xs: 0, sm: 5 }, ml: { xs: 2, sm: 0 } }}>
                            <Typography variant="displayMd" sx={{ color: Straw }}>
                                <Trans i18nKey="LandingPage.section1Title" />
                            </Typography>
                            <Typography variant="textLg" sx={{ color: White, lineHeight: '32px' }}>
                                <Trans i18nKey="LandingPage.section1Description" />
                            </Typography>
                            <Button
                                href={demoLink}
                                variant="outlined"
                                sx={{ color: White, borderColor: White, width: 200, mt: '32px' }}>
                                <Trans i18nKey="LandingPage.getDemoButton" />
                            </Button>
                        </Stack>
                        <Box component="img" src={image1} alt="Image1" sx={{ ...imageSize }} />;
                    </Stack>
                </Box>
                {/* Section 2 */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        minHeight: { xs: 1000, sm: 900, md: 575 },
                        paddingX: { xs: 3, sm: 7, md: 12 },
                        paddingY: { xs: 5, sm: 7, md: 10 },
                        boxSizing: 'border-box',
                    }}>
                    <Card
                        sx={{
                            backgroundColor: SoftBG,
                            height: '100%',
                            width: '100%',
                        }}>
                        <Stack
                            direction={responsiveDirection}
                            sx={{
                                height: '100%',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}>
                            <Stack gap={1} sx={{ m: 5 }}>
                                <Typography variant="displayMd">
                                    <Trans i18nKey="LandingPage.howItWorksTitle" />
                                </Typography>
                                <Typography variant="textLg" sx={{ lineHeight: '32px' }}>
                                    <Trans i18nKey="LandingPage.howItWorksDescription" />
                                </Typography>
                                <Button href={demoLink} variant="contained" sx={{ maxWidth: 150, mb: 4, mt: 2 }}>
                                    <Trans i18nKey="LandingPage.learnMoreButton" />
                                </Button>
                            </Stack>
                            <Box
                                component="img"
                                src={screen1}
                                alt="Image1"
                                sx={{
                                    height: '375px',
                                    width: 'auto',
                                    mr: { xs: 5, sm: 10 },
                                    alignSelf: { xs: 'center', sm: 'flex-end' },
                                }}
                            />
                        </Stack>
                    </Card>
                </Box>
                {/* Section 3 */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        minHeight: { xs: 950, md: 553 },
                        boxSizing: 'border-box',
                    }}>
                    <Stack gap={{ xs: 2, sm: 6 }} direction={responsiveDirection} sx={{ alignItems: 'center' }}>
                        <Box component="img" src={image2} alt="Image2" sx={imageSize} />
                        <Stack gap={1} sx={{ maxWidth: 542, mx: 3 }}>
                            <Typography variant="displayMd" sx={{ color: Midnight }}>
                                <Trans i18nKey="LandingPage.growBusinessTitle" />
                            </Typography>
                            <Stack gap={4}>
                                <Typography variant="TextLg" sx={{ color: Midnight, lineHeight: '32px' }}>
                                    <Trans i18nKey="LandingPage.growBusinessDescription1" />
                                </Typography>
                                <Typography variant="TextLg" sx={{ color: Midnight, lineHeight: '32px' }}>
                                    <Trans i18nKey="LandingPage.growBusinessDescription2" />
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
                {/* Section 4 */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        minHeight: { xs: 950, md: 553 },
                        boxSizing: 'border-box',
                    }}>
                    <Stack gap={{ xs: 2, sm: 6 }} direction={responsiveDirection} sx={{ alignItems: 'center' }}>
                        <Stack gap={1} sx={{ maxWidth: 542, mx: 3 }}>
                            <Typography variant="displayMd" sx={{ color: Midnight }}>
                                <Trans i18nKey="LandingPage.getStartedTitle" />
                            </Typography>
                            <Stack gap={4}>
                                <Typography variant="TextLg" sx={{ color: Midnight, lineHeight: '32px' }}>
                                    <Trans i18nKey="LandingPage.getStartedDescription1" />
                                </Typography>
                                <Typography variant="TextLg" sx={{ color: Midnight, lineHeight: '32px' }}>
                                    <Trans i18nKey="LandingPage.getStartedDescription2" />
                                </Typography>
                            </Stack>
                        </Stack>
                        <Box component="img" src={image3} alt="Image3" sx={imageSize} />
                    </Stack>
                </Box>
                {/* Section 5 */}
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden',
                        backgroundColor: Tide,
                        paddingY: 8,
                        paddingX: { xs: 10, md: 15 },
                        mx: 'auto',
                    }}>
                    <Stack gap={7} sx={{ alignItems: 'center', width: '100%' }}>
                        <Typography variant="displayMd" sx={{ color: Straw, textAlign: 'center', mx: 2 }}>
                            <Trans i18nKey="LandingPage.completeSolutionTitle" />
                        </Typography>
                        <Grid
                            container
                            spacing={{ xs: 8, sm: 6, md: 4 }} // Set minimum spacing
                            justifyContent="center"
                            alignItems="stretch"
                            sx={{ width: '100%' }}>
                            <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center', minWidth: 280, flexShrink: 0 }}>
                                <LandingIconCard
                                    icon={PackageIcon}
                                    title={<Trans i18nKey="LandingPage.card1Title" />}
                                    description={<Trans i18nKey="LandingPage.card1Description" />}
                                    sx={{ width: '100%', maxWidth: 300, height: 550 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center', minWidth: 280, flexShrink: 0 }}>
                                <LandingIconCard
                                    icon={ChatIcon}
                                    title={<Trans i18nKey="LandingPage.card2Title" />}
                                    description={<Trans i18nKey="LandingPage.card2Description" />}
                                    sx={{ width: '100%', maxWidth: 300, height: 550 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center', minWidth: 280, flexShrink: 0 }}>
                                <LandingIconCard
                                    icon={CreditCardIcon}
                                    title={<Trans i18nKey="LandingPage.card3Title" />}
                                    description={<Trans i18nKey="LandingPage.card3Description" />}
                                    sx={{ width: '100%', maxWidth: 300, height: 550 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'center', minWidth: 280, flexShrink: 0 }}>
                                <LandingIconCard
                                    icon={MicroChipIcon}
                                    title={<Trans i18nKey="LandingPage.card4Title" />}
                                    description={<Trans i18nKey="LandingPage.card4Description" />}
                                    sx={{ width: '100%', maxWidth: 300, height: 550 }}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            href={demoLink}
                            variant="contained"
                            sx={{ backgroundColor: White, color: Midnight, width: 284 }}>
                            <Trans i18nKey="LandingPage.getStartedButton" />
                        </Button>
                    </Stack>
                </Box>

                {/* Section 6 */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '750px',
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    <Stack gap={2} sx={{ width: 1104, alignItems: 'center' }}>
                        <Typography variant="displayMd" sx={{ color: Straw, textAlign: 'center', mx: 2 }}>
                            <Trans i18nKey="LandingPage.testimonial" />
                        </Typography>
                        <Typography
                            variant="textSm"
                            sx={{ textAlign: 'center', color: White, fontWeight: 800, letterSpacing: 2.8, mx: 3 }}>
                            <Trans i18nKey="LandingPage.testimonialName" />
                        </Typography>
                    </Stack>
                </Box>
                {/* Footer */}
                <LandingFooter />
            </Stack>
        </Box>
    );
}
