import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material';
import LoadingButton from '../../components/LoadingButton/LoadingButton.jsx';
import { useFetcher, useNavigate } from 'react-router-dom';
import SignInTextBG from '../../components/SignInTextBG/SignInTextBG.jsx';
import Box from '@mui/material/Box';
import FormInputLabel from '../../components/FormInputLabel/FormInputLabel.jsx';
import TextField from '@mui/material/TextField';
import { usePassword } from './PasswordProvider.jsx';
import PasswordTextField from '../../components/PasswordTextField/PasswordTextField.jsx';
import { useTranslation } from 'react-i18next';
import WholeshopLogoIcon from '../../icons/WholeshopLogoIcon.jsx';

export const mapErrorMessage = (error) => {
    if (error.toString().includes('EmptySignInUsername')) {
        return 'Email required';
    } else if (error.toString().includes('EmptySignInPassword')) {
        return 'Password required';
    } else {
        return 'Sign in failed, please try again';
    }
};

export default function SignIn() {
    const theme = useTheme();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const fetcher = useFetcher();
    const isSaving = fetcher.state === 'submitting';
    const { setPassword: setPersistentPassword } = usePassword();
    const { t } = useTranslation();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPersistentPassword(newPassword);
        setPassword(newPassword);
    };

    const handleSignUpSelect = () => {
        navigate('/signUp');
    };

    const handleForgotPasswordSelected = () => {
        navigate('/forgotPassword');
    };

    return (
        <Stack direction="row" sx={{ height: '100vh', width: '100%' }}>
            <Stack
                sx={{
                    flex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 10,
                    mx: 5,
                }}>
                <fetcher.Form method="POST" style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <Stack gap={5} sx={{ alignItems: 'flex-start', flexGrow: 1 }}>
                        <WholeshopLogoIcon />
                        <Stack gap={3}>
                            <Stack>
                                <Typography variant="displayMd">{t('SignIn.signInLabel')}</Typography>
                                <Typography sx={{ color: theme.palette.common.LightGray }}>
                                    {t('SignIn.signInPrompt')}
                                </Typography>
                            </Stack>
                            <FormInputLabel text={t('SignIn.emailLabel')}>
                                <TextField
                                    fullWidth
                                    name="email"
                                    placeholder="Enter email address"
                                    value={email}
                                    onChange={handleEmailChange}
                                    autoComplete="username"
                                />
                            </FormInputLabel>
                            <FormInputLabel text={t('SignIn.passwordLabel')}>
                                <PasswordTextField
                                    fullWidth
                                    name="password"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    autoComplete="current-password"
                                />
                            </FormInputLabel>
                            {fetcher.data?.error && fetcher.state !== 'submitting' ? (
                                <Typography
                                    sx={{
                                        color: theme.palette.common.Alert,
                                    }}>{`Error: ${mapErrorMessage(fetcher.data?.error)}`}</Typography>
                            ) : null}
                            <Stack gap={1}>
                                <LoadingButton
                                    isLoading={isSaving}
                                    loadingColor="white"
                                    type="submit"
                                    variant="contained"
                                    color="primary">
                                    {t('SignIn.signInLabel')}
                                </LoadingButton>
                                <Button
                                    variant="underlined"
                                    onClick={handleForgotPasswordSelected}
                                    sx={{ color: theme.palette.common.LightGray }}>
                                    {t('SignIn.forgotPasswordButton')}
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="row"
                        sx={{
                            mt: 'auto',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: 2,
                            paddingBottom: '20px',
                        }}>
                        <Typography sx={{ color: theme.palette.common.Teal }}>
                            {t('SignIn.haveAccountLabel')}
                        </Typography>
                        <Button
                            onClick={handleSignUpSelect}
                            variant="underlined"
                            sx={{
                                color: theme.palette.common.Midnight,
                                padding: '4px 4px',
                                minWidth: 'auto',
                                fontSize: '16px',
                            }}>
                            {t('SignIn.signUpButton')}
                        </Button>
                    </Stack>
                </fetcher.Form>
            </Stack>
            <Box
                sx={{
                    flex: 1,
                    display: {
                        xs: 'none',
                        sm: 'block',
                    },
                }}>
                <SignInTextBG text={t('SignIn.welcomeLabel')} />
            </Box>
        </Stack>
    );
}
