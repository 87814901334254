import React, { useState } from 'react';
import { Box, Button, List, ListItem, TextField, Typography, Alert } from '@mui/material';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import CopyableTooltip from '../../../../components/CopyableTooltip/CopyableTooltip.jsx';
import Stack from '@mui/material/Stack';

export default function TrackingNumbers({ initialTrackingNumbers, onTrackingNumbersUpdate }) {
    const [trackingNumbers, setTrackingNumbers] = useState(initialTrackingNumbers || []);
    const [newTrackingNumber, setNewTrackingNumber] = useState('');
    const [error, setError] = useState('');

    const handleAddTrackingNumber = () => {
        if (!newTrackingNumber) {
            const errorMessage = 'Please enter a tracking number.';
            setError(errorMessage);
            return;
        }
        if (trackingNumbers.includes(newTrackingNumber)) {
            const errorMessage = 'This tracking number has already been added.';
            setError(errorMessage);
            return;
        }
        setError('');
        try {
            const updatedTrackingNumbers = [...trackingNumbers, newTrackingNumber];
            setTrackingNumbers(updatedTrackingNumbers);
            setNewTrackingNumber('');
            onTrackingNumbersUpdate(updatedTrackingNumbers);
        } catch (error) {
            Sentry.captureException(error);
            setError('An error occurred while adding the tracking number.');
        }
    };

    const handleRemoveTrackingNumber = (index) => {
        try {
            const updatedTrackingNumbers = trackingNumbers.filter((_, i) => i !== index);
            setTrackingNumbers(updatedTrackingNumbers);
            onTrackingNumbersUpdate(updatedTrackingNumbers);
        } catch (error) {
            Sentry.captureException(error);
            setError('An error occurred while removing the tracking number.');
        }
    };

    return (
        <Box>
            <Typography variant="h6">Tracking Numbers:</Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <List>
                {trackingNumbers.map((number, index) => (
                    <ListItem
                        sx={{ width: '100%', display: 'flex', paddingBottom: '4px', paddingTop: '4px', paddingX: 0 }}
                        key={number}
                        divider>
                        <Stack
                            sx={{ display: 'flex', width: '100%' }}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between">
                            <CopyableTooltip text={number}>
                                <Typography
                                    align="left"
                                    sx={{
                                        flexGrow: 1,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        marginRight: 1,
                                        whiteSpace: 'nowrap',
                                        fontSize: '14px',
                                    }}>
                                    {number}
                                </Typography>
                            </CopyableTooltip>
                            <Button
                                variant="outlined"
                                onClick={() => handleRemoveTrackingNumber(index)}
                                sx={{
                                    width: 80,
                                    flexShrink: 0,
                                }}>
                                Remove
                            </Button>
                        </Stack>
                    </ListItem>
                ))}
            </List>
            <Stack
                sx={{ display: 'flex', width: '100%' }}
                direction="row"
                alignItems="center"
                justifyContent="space-between">
                <TextField
                    sx={{
                        flexGrow: 1,
                        marginRight: 1,
                    }}
                    label="New Tracking Number"
                    value={newTrackingNumber}
                    onChange={(e) => setNewTrackingNumber(e.target.value)}
                    variant="outlined"
                    size="small"
                    error={!!error}
                />
                <Button
                    sx={{ width: 80 }}
                    variant="contained"
                    onClick={handleAddTrackingNumber}
                    disabled={!newTrackingNumber}>
                    Add
                </Button>
            </Stack>
        </Box>
    );
}

TrackingNumbers.propTypes = {
    initialTrackingNumbers: PropTypes.arrayOf(PropTypes.string),
    onTrackingNumbersUpdate: PropTypes.func.isRequired,
};
