import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessTypes } from '../../constants/BusinessTypes';

function BusinessTypeSelect(props) {
    const { label, onChange, selectProps, defaultValue = BusinessTypes.BRAND } = props;
    const [value, setValue] = React.useState(defaultValue);

    const { t } = useTranslation();

    const handleChange = (event) => {
        setValue(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="business-type-select-label">{label}</InputLabel>
            <Select
                labelId="business-type-select-label"
                id="business-type-select"
                value={value}
                onChange={handleChange}
                label={label}
                fullWidth
                {...selectProps}>
                <MenuItem value={BusinessTypes.BRAND}>{t('BusinessTypeSelect.brandOptionLabel')}</MenuItem>
                <MenuItem value={BusinessTypes.RETAIL}>{t('BusinessTypeSelect.retailerOptionLabel')}</MenuItem>
            </Select>
        </FormControl>
    );
}

BusinessTypeSelect.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    selectProps: PropTypes.any,
    defaultValue: PropTypes.string,
};

export default BusinessTypeSelect;
