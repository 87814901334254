import React from 'react';
import PropTypes from 'prop-types';
import { Stack, InputLabel } from '@mui/material';
import { Alert } from '../../theme.js';

export default function FormInputLabel({ text, required, children, adornment, sx }) {
    return (
        <Stack gap={1} sx={{ alignItems: 'flex-start', width: '100%' }}>
            <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                {text && (
                    <InputLabel
                        sx={{
                            fontSize: '14px',
                            fontWeight: 800,
                            letterSpacing: '2.8px',
                            color: 'common.Teal',
                            textTransform: 'uppercase',
                            ...sx,
                        }}>
                        {text}
                        {required && <span style={{ color: Alert }}>*</span>}
                    </InputLabel>
                )}
                {adornment && <Stack sx={{ marginLeft: 2 }}>{adornment}</Stack>}
            </Stack>
            {children}
        </Stack>
    );
}

FormInputLabel.propTypes = {
    text: PropTypes.string,
    required: PropTypes.bool,
    children: PropTypes.element,
    adornment: PropTypes.element,
    sx: PropTypes.any,
};
