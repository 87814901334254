// useCart.js
import { useContext } from 'react';
import { CartContext } from './CartContext';
import { calculateOrderDiscountTotal } from '../routes/cart/cartUtil';

/**
 * @typedef useCartInterface
 * @property {object} state,
 * @property {function} addItem,
 * @property {function} addProductRef,
 * @property {function} removeItem,
 * @property {function} clearCart,
 * @property {function} updateAddress,
 * @property {function} updateItem
 * @property {function} updateOrderDetails,
 * @property {function} updateRetailBusiness
 */

/**
 *
 * @returns {useCartInterface}
 */
export function useCart() {
    const context = useContext(CartContext);
    if (context === undefined) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
}

export function selectProducts(state, options = {}) {
    const products = state?.productRefs ?? [];
    const { sort = 'title' } = options;
    const sortFn = {
        title: (a, b) => a - b,
        '-title': (a, b) => a - b,
    }[sort];
    if (sortFn) {
        products.sort(sortFn);
    }
    return products;
}

export function selectProductById(state, id, options) {
    return selectProducts(state, options)?.find((product) => product.id === id);
}

export function selectItems(state, options = {}) {
    let items = state?.items ?? [];
    if (options.hasQuantity) {
        items = items.filter((i) => i.quantity);
    }
    if (options.productId) {
        items = items.filter((i) => i.productId === options.productId);
    }
    return items;
}

/**
 * Selects an item by id
 * @param {object} state
 * @param {number} id
 * @returns {object} item
 */
export function selectItemById(state, id) {
    return selectItems(state)?.find((i) => i.id === id);
}

/**
 * Sums the quantity of product variants of a product
 * @param {*} state
 * @returns
 */
export function selectTotalQuantity(state, options = {}) {
    const { id, productId } = options;
    let items = selectItems(state);

    // apply filters
    if (id) {
        items = items.filter((i) => i.id === id);
    }
    if (productId) {
        items = items.filter((i) => i.productId === productId);
    }
    return (
        items.reduce((acc, curr) => {
            if (curr?.productVariants) {
                return acc + curr.productVariants.reduce((acc, curr) => acc + (curr?.quantity ?? 0), 0);
            }
            return acc + (curr?.quantity ?? 0);
        }, 0) ?? 0
    );
}

/**
 *
 * @param {object} state
 * @returns {number} subtotal
 */
export function selectSubtotalValue(state) {
    const items = selectItems(state);
    const productVariants = items.reduce((acc, curr) => {
        // The case where items have productVariants means the items are products and not variants themselves.
        if (curr.productVariants) {
            return [
                ...acc,
                ...curr.productVariants.map(({ quantity, wholesale }) => ({
                    quantity,
                    wholesale,
                })),
            ];
        }
        return [...acc, curr];
    }, []);
    return productVariants.reduce(
        (acc, curr) => acc + Number(curr.price !== undefined ? curr.price : curr.wholesale) * curr.quantity,
        0
    );
}

export function selectOrderTotalValue(state) {
    const subtotal = selectSubtotalValue(state);
    const discountTotal = calculateOrderDiscountTotal({
        discountAmount: state.discountAmount,
        discountType: state.discountType,
        subTotal: subtotal,
    });
    return subtotal + (Number(state?.shippingCost) ?? 0) - Number(discountTotal);
}
