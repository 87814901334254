import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormInputLabel from '../FormInputLabel/FormInputLabel.jsx';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PencilSimpleIcon from '../../icons/PencilSimpleIcon.jsx';
import { Midnight, Teal } from '../../theme.js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

export default function DealerSettingsSidebar({ connectionSettings, availableStatuses, onSaveNote, onStatusChange }) {
    const availablePaymentTerms = [];
    const availablePricingGroups = [];
    const [currentStatus, setCurrentStatus] = useState(connectionSettings.status ?? '');
    const [customerType] = useState('Retailer');
    const [paymentTerms, setPaymentTerms] = useState('');
    const [pricingGroup, setPricingGroup] = useState('');
    const [isEditingNote, setIsEditingNote] = useState(false);
    const [note, setNote] = useState(connectionSettings.note ?? 'Testing Notes');
    const flags = useFlags();
    const { t } = useTranslation();

    const handleStatusChange = (event) => {
        onStatusChange(event.target.value);
        setCurrentStatus(event.target.value);
    };

    const handlePaymentTermsChange = (value) => {
        setPaymentTerms(value.target.value);
    };

    const handlePricingGroupChange = (value) => {
        setPricingGroup(value.target.value);
    };

    const saveNote = () => {
        onSaveNote(note);
        setIsEditingNote(false);
    };

    const cancelNoteEdit = () => {
        setIsEditingNote(false);
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return (
        <Stack gap={2} sx={{ minWidth: '300px', maxWidth: 200 }}>
            <Card>
                <CardContent x={{ padding: '24px' }}>
                    <FormInputLabel text={t('Dealers.statusLabel')} sx={{ fontSize: 12 }}>
                        <Select value={currentStatus} onChange={handleStatusChange} sx={{ width: '100%', height: 40 }}>
                            {availableStatuses.map((i) => (
                                <MenuItem key={i} value={i}>
                                    <Typography variant="textSm" sx={{ fontWeight: 500, pt: 0.3 }}>
                                        {capitalize(i)}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormInputLabel>
                </CardContent>
            </Card>
            <Card>
                <CardContent x={{ padding: '24px' }}>
                    <Stack gap={2}>
                        <FormInputLabel text={t('Dealers.customerTypelabel')} sx={{ fontSize: 12 }}>
                            <Select value={customerType} disabled sx={{ width: '100%', height: 40 }}>
                                <MenuItem value={customerType}>
                                    <Typography variant="textSm" sx={{ fontWeight: 500, pt: 0.3 }}>
                                        Retailer
                                    </Typography>
                                </MenuItem>
                            </Select>
                        </FormInputLabel>
                        {flags.pricingGroups ? (
                            <Box>
                                <FormInputLabel text={t('Dealers.paymentTermsLabel')} sx={{ fontSize: 12 }}>
                                    <Select
                                        value={paymentTerms}
                                        onChange={handlePaymentTermsChange}
                                        sx={{ width: '100%', height: 40 }}>
                                        {availablePaymentTerms.map((i) => (
                                            <MenuItem key={i} value={i}>
                                                <Typography variant="textSm" sx={{ fontWeight: 500, pt: 0.3 }}>
                                                    {i}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormInputLabel>
                                <FormInputLabel text={t('Dealers.pricingGroupLabel')} sx={{ fontSize: 12 }}>
                                    <Select
                                        value={pricingGroup}
                                        onChange={handlePricingGroupChange}
                                        sx={{ width: '100%', height: 40 }}>
                                        {availablePricingGroups.map((i) => (
                                            <MenuItem key={i} value={i}>
                                                <Typography variant="textSm" sx={{ fontWeight: 500, pt: 0.3 }}>
                                                    {i}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormInputLabel>
                            </Box>
                        ) : null}
                    </Stack>
                </CardContent>
            </Card>
            <Card>
                <CardContent x={{ padding: '24px' }}>
                    <Stack gap={1}>
                        <FormInputLabel
                            text={t('Dealers.noteLabel')}
                            sx={{ fontSize: 12 }}
                            adornment={
                                !isEditingNote ? (
                                    <IconButton onClick={() => setIsEditingNote(true)} sx={{ padding: 0 }}>
                                        <PencilSimpleIcon sx={{ width: 18.75, height: 18.75, color: Teal }} />
                                    </IconButton>
                                ) : null
                            }>
                            {isEditingNote ? (
                                <TextField
                                    multiline
                                    rows={5}
                                    fullWidth
                                    value={note}
                                    onChange={(event) => setNote(event.target.value)}
                                    InputProps={{
                                        style: { fontSize: 14, fontWeight: 500, color: Midnight },
                                    }}
                                />
                            ) : (
                                <Typography variant="textSm" sx={{ fontWeight: 500, color: Midnight }}>
                                    {note}
                                </Typography>
                            )}
                        </FormInputLabel>
                        {isEditingNote ? (
                            <Stack gap={1} direction="row">
                                <Button
                                    onClick={cancelNoteEdit}
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ height: 32 }}>
                                    Cancel
                                </Button>
                                <Button onClick={saveNote} variant="contained" sx={{ height: 32 }}>
                                    Save
                                </Button>
                            </Stack>
                        ) : null}
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    );
}

DealerSettingsSidebar.propTypes = {
    connectionSettings: PropTypes.object.isRequired,
    availableStatuses: PropTypes.array.isRequired,
    onSaveNote: PropTypes.func,
    onStatusChange: PropTypes.func,
};
