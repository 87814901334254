import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { matchIsValidTel } from 'mui-tel-input';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressInputType } from '../../constants/AddressInputType.jsx';
import { isValidEmail } from '../../helpers/HelperFunctions.jsx';
import LoadingButton from '../LoadingButton/LoadingButton.jsx';
import PhoneInput from '../PhoneInput/PhoneInput.jsx';
import AddressCountrySelect from './AddressCountrySelect.jsx';
import AddressStateSelect from './AddressStateSelect.jsx';

export default function AddAddressForm(props) {
    const {
        address,
        onCancel,
        onDelete,
        onConfirm,
        type,
        isPrimaryAddress,
        isCheckout = false,
        isLoading = false,
    } = props;
    const [primarySelected, setPrimarySelected] = useState(isPrimaryAddress);
    const { t } = useTranslation();
    const textfieldStyle = { '& > :not(style)': { mt: 1, mb: 2 } };
    const [isFormValid, setIsFormValid] = useState(false);
    const isEditing = type === AddressInputType.EDIT;
    const title =
        type === AddressInputType.DEALER
            ? 'Create new dealer location'
            : isEditing
              ? 'Edit Address'
              : 'Enter a new address';
    const submitTitle = isEditing ? 'Update Address' : 'Add Address';
    const [textFieldErrors, setTextFieldErrors] = useState({
        phone: false,
        secondaryPhone: false,
        email: false,
    });

    const [formData, setFormData] = useState({
        label: address?.label ?? '',
        name: address?.name ?? '',
        firstName: address?.firstName ?? '',
        lastName: address?.lastName ?? '',
        phoneNumber: address?.phones[0]?.primary ?? '',
        secondaryPhoneNumber: address?.phones[0]?.secondary ?? '',
        email: address?.emails[0]?.default ?? '',
        street1: address?.street1 ?? '',
        street2: address?.street2 ?? '',
        city: address?.city ?? '',
        state: address?.state ?? '',
        postalCode: address?.postalCode ?? '',
        countryCode: address?.countryCode ?? '',
        primaryAddress: isPrimaryAddress,
    });
    const handleTextChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlePhoneNumberChange = (event) => {
        setFormData({
            ...formData,
            phoneNumber: event,
        });
    };

    const handleSecondaryPhoneNumberChange = (event) => {
        setFormData({
            ...formData,
            secondaryPhoneNumber: event,
        });
    };

    const handlePrimaryAddressCheckbox = (event) => {
        const checked = event.target.checked;
        setFormData({
            ...formData,
            isPrimaryAddress: checked,
        });
        setPrimarySelected(checked);
    };

    const validateForm = () => {
        const validFullName = isCheckout ? formData.name !== '' : true;
        const validEmail = formData.email !== '' && isValidEmail(formData.email);
        const validPhoneNumber = matchIsValidTel(formData.phoneNumber);
        const validSecondPhoneNumber =
            formData.secondaryPhoneNumber === '' ? true : matchIsValidTel(formData.secondaryPhoneNumber);
        const validStreet = formData.street !== '';
        const validCity = formData.city !== '';
        const validState = formData.state !== '';
        const validPostalCode = formData.postalCode !== '';
        const validCountryCode = formData.countryCode !== '';
        setTextFieldErrors({
            phone: !_.isEmpty(formData.phoneNumber) && !validPhoneNumber,
            secondaryPhone: !_.isEmpty(formData.secondaryPhoneNumber) && !validSecondPhoneNumber,
            email: !_.isEmpty(formData.email) && !validEmail,
        });
        return (
            validFullName &&
            validEmail &&
            validPhoneNumber &&
            validStreet &&
            validCity &&
            validState &&
            validPostalCode &&
            validSecondPhoneNumber &&
            validCountryCode
        );
    };

    const onSubmit = () => {
        onConfirm(formData);
    };

    const handleDelete = () => {
        onDelete();
    };

    useEffect(() => {
        setIsFormValid(validateForm);
    }, [formData]);

    return (
        <Box sx={{ backgroundColor: 'white', borderRadius: '6px', maxWidth: 600 }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '20px' }}>{title}</Typography>
            <form noValidate>
                <Box sx={textfieldStyle}>
                    {isCheckout ? (
                        <Box sx={textfieldStyle}>
                            <TextField
                                fullWidth
                                required
                                label={
                                    type === AddressInputType.DEALER
                                        ? 'Business Name'
                                        : 'Full name (First and Last name or Business name)'
                                }
                                placeholder="Ship to name"
                                name="name"
                                variant="outlined"
                                value={formData.name}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleTextChange}
                            />
                            {type === AddressInputType.DEALER ? (
                                <Stack direction="row" gap={2}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="First Name"
                                        placeholder="First Name"
                                        name="firstName"
                                        variant="outlined"
                                        value={formData.firstName}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={handleTextChange}
                                    />
                                    <TextField
                                        fullWidth
                                        required
                                        label="Last Name"
                                        placeholder="Last Name"
                                        name="lastName"
                                        variant="outlined"
                                        value={formData.lastName}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={handleTextChange}
                                    />
                                </Stack>
                            ) : null}
                        </Box>
                    ) : (
                        <TextField
                            fullWidth
                            required
                            label="Label"
                            placeholder="Address Type"
                            name="label"
                            variant="outlined"
                            value={formData.label}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleTextChange}
                        />
                    )}
                    <PhoneInput
                        label="Phone Number"
                        name="phoneNumber"
                        required
                        fullWidth
                        value={formData.phoneNumber}
                        InputLabelProps={{ shrink: true }}
                        error={textFieldErrors.phone}
                        onChange={handlePhoneNumberChange}
                    />
                    {type !== AddressInputType.DEALER ? (
                        <PhoneInput
                            label="Secondary Phone Number"
                            name="secondaryPhoneNumber"
                            fullWidth
                            value={formData.secondaryPhoneNumber}
                            InputLabelProps={{ shrink: true }}
                            error={textFieldErrors.secondaryPhone}
                            onChange={handleSecondaryPhoneNumberChange}
                        />
                    ) : null}
                    <TextField
                        fullWidth
                        required
                        label="Email"
                        placeholder="Email"
                        variant="outlined"
                        value={formData.email}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleTextChange}
                        name="email"
                        error={textFieldErrors.email}
                        autoComplete="email"
                    />
                    <TextField
                        fullWidth
                        required
                        label="Street Address or P.O. Box"
                        placeholder="Address"
                        variant="outlined"
                        value={formData.street1}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleTextChange}
                        name="street1"
                    />
                    <TextField
                        fullWidth
                        label="Apt, Suite, unit, building, floor, etc."
                        placeholder="Address 2"
                        variant="outlined"
                        value={formData.street2}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleTextChange}
                        name="street2"
                    />
                    <Stack gap={2} sx={{ flexDirection: { xs: 'column', sm: 'row' }, width: '100%' }}>
                        <TextField
                            fullWidth
                            required
                            label="City"
                            placeholder="City"
                            variant="outlined"
                            value={formData.city}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleTextChange}
                            name="city"
                        />
                        <AddressStateSelect
                            selectProps={{ name: 'state', width: '100%' }}
                            label={t('BusinessForm.state')}
                            defaultValue={formData.state}
                            onChange={handleTextChange}
                        />
                        <TextField
                            fullWidth
                            required
                            type="number"
                            label="ZIP Code"
                            placeholder="ZIP Code"
                            variant="outlined"
                            name="postalCode"
                            value={formData.postalCode}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleTextChange}
                        />
                    </Stack>
                    <AddressCountrySelect
                        sx={{ width: '225px' }}
                        selectProps={{ name: 'countryCode' }}
                        label={'Country'}
                        defaultValue={formData.countryCode}
                        onChange={handleTextChange}
                    />
                </Box>
                {type !== AddressInputType.DEALER ? (
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={isPrimaryAddress || isCheckout}
                                checked={primarySelected}
                                onChange={handlePrimaryAddressCheckbox}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Primary Address"
                    />
                ) : null}

                <Stack gap={3} direction="row" sx={{ margin: '20px' }}>
                    <Button fullWidth onClick={onCancel} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                    <LoadingButton
                        isLoading={isLoading}
                        disabled={!isFormValid}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={onSubmit}>
                        {submitTitle}
                    </LoadingButton>
                </Stack>

                {isEditing && (
                    <Button
                        variant="text"
                        sx={{ color: 'red', backgroundColor: 'transparent', mt: 2 }}
                        fullWidth
                        onClick={handleDelete}>
                        Delete
                    </Button>
                )}
            </form>
        </Box>
    );
}

AddAddressForm.propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    onDelete: PropTypes.func,
    onTogglePrimaryAddress: PropTypes.func,
    address: PropTypes.any,
    type: PropTypes.string,
    isCheckout: PropTypes.bool,
    isPrimaryAddress: PropTypes.bool,
    isLoading: PropTypes.bool,
};
