import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { isEmpty } from '../helpers/HelperFunctions.jsx';
import ImagesIcon from '../icons/ImagesIcon.jsx';

export default function ProductIcon({ alt, src, sx, objectFit = 'contain', CardMediaProps, IconProps }) {
    const [imgSource, setImgSource] = useState(src);
    const handleImageError = () => {
        setImgSource(null);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...sx,
            }}>
            {!isEmpty(imgSource) ? (
                <CardMedia
                    component="img"
                    alt={alt}
                    sx={{ width: '100%', height: '100%', objectFit, ...CardMediaProps?.sx }}
                    image={imgSource}
                    onError={handleImageError}
                />
            ) : (
                <ImagesIcon sx={{ width: '55%', height: '75%', color: 'gray', ...IconProps?.sx }} />
            )}
        </Box>
    );
}

ProductIcon.propTypes = {
    alt: PropTypes.string,
    src: PropTypes.string,
    sx: PropTypes.object,
    objectFit: PropTypes.string,
    CardMediaProps: PropTypes.any,
    IconProps: PropTypes.any,
};
